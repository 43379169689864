const subview_loader = require('../subview_loader'),
      page = require('../../utilities/page'),
      ajax = require('../../utilities/ajax'),
      history = require('../../utilities/history'),
      spinner = require('../../utilities/spinner'),
      dialog = require('bootstrap3-dialog');

subview_loader.on({
    enter: function(response) {
        if (response.name == "form.section") {
            init(response);
        }
    }
});

function init(response) {
    showCurrentRepetitions(response);
    bindUpdateRepetitions(response);
}

function showCurrentRepetitions(response) {
    let $r = $("#repeat_question"),
        val = parseInt($r.val());

    if (validateRepetitions()) {
        $("#invalid-num").addClass("hidden");

        renderSectionRepetitions(val);
    }
    
}

function bindUpdateRepetitions(response) {
    $("#update_repetitions").on('click', function(event) {
        event.preventDefault();

        let $message = $("#invalid-num"),
            $this = $(this),
            $r = $("#repeat_question"),
            val = parseInt($r.val()),
            original = parseInt($r.data('original')),
            sc = response.vars.service_code,
            ptn = response.vars.primary_ticket_num,
            tn = response.vars.ticket_num,
            f = response.vars.data.sc_ticket_form.sc_ticket_form_id,
            fs = response.vars.data.section.form_section_id,
            data = {
                repetitions: val
            };
        
        spinner.show($this, true);

        ajax.put(page.url('form.section', [sc, ptn, tn, f, fs]), data, function(res) {
            if (res.success) {
                ajax.clearCache();
                $(document).trigger('render-sidebar');

                if (val == 0) {
                    history.pushState(null, document.title, page.url('form', [sc, ptn, tn, f]));
                } else {
                    renderSectionRepetitions(val);
                    $r.data('original', val);
                    $message.addClass("hidden");

                    spinner.hide($this);
                }
            } else {
                dialog.alert(res.message);
                $r.val(original);
                renderSectionRepetitions(original);

                if (!validateRepetitions()) {
                    $message.removeClass("hidden");
                }

                spinner.hide($this);
            }
        }, function(jqXHR, textStatus, errorThrown) {
            spinner.hide($this);

            if (jqXHR.status == 403) {
                window.location.href();
            }
        });

    });
}

function validateRepetitions() {
    let $r = $("#repeat_question"),
        min = parseInt($r.attr('min')),
        max = parseInt($r.attr('max')),
        val = parseInt($r.val());
    
    return (val >= min && val <= max);
}

function bindNumRepeats() {
    $("#repeat_question").on('input', function(event) {
        let $formGroup = $(this).parent(),
            $message = $("#invalid-num"),
            min = parseInt($(this).attr('min')),
            max = parseInt($(this).attr('max')),
            val = parseInt($(this).val());

        $("#repetitions").html("");
        
        if (val >= min && val <= max) {
            $formGroup.removeClass("has-error");
            $message.addClass("hidden");

            renderSectionRepetitions(val);
        } else {
            $message.removeClass("hidden");
            $formGroup.addClass("has-error");
        }
    });
}

function renderSectionRepetitions(count) {
    let $rep, href,
        completed = parseInt($("#repetitions").data('num-complete'));

    $("#repetitions").html("");

    for (let i = 1; i <= count; i++) {
        if (i - 1 > completed) {
            $rep = $("#section-repetition-disabled-template .section-repetition").clone();
        } else {
            $rep = $("#section-repetition-template .section-repetition").clone();
            href = $("a", $rep).attr('href');

            $("a", $rep).attr("href", href + "/" + i);

            if (i <= completed) {
                $("a", $rep).removeClass("card-info").addClass("card-success");
            }
        }
        
        $("[name=question-groupnumber]", $rep).html(i);
        $("#repetitions").append($rep);
    }
}
