const page = require('./utilities/page');

/**
 * Require Functions
 */

const requireSearch = function() {
	require('./tooltip');
	require('./tech/search');
};

const requireTechService = function() {
	require('jquery-ui/ui/widget');
	require('./tech/service_details');
	require('./thumb');
};

const requireTechServiceUploadPhotos = function() {
	requireTechService();
	require('./tech/upload_photos');
};

const requireTechServiceUploadDocs = function() {
	requireTechService();
	require('./tech/upload_docs');
};

const requireTechServiceTasks = function() {
	requireTechService();
	require('./tech/tasks');
};

const requireTechServiceTechNotes = function() {
	requireTechService();
	require('./tech/technotes');
};

const requireTechServiceParts = function() {
	requireTechService();
	require('./tech/parts');
};

const requireTechServiceForms = function() {
	requireTechService();
	require('./tech/forms');
};

const requireTechServiceForm = function() {
	requireTechService();
	require('./tech/form');
};

const requireTechServiceFormSection = function() {
	requireTechService();
	require('./tech/form/section');
	require('./tech/form/questions');
	require('./tech/photopick');
};

const requireTooltip = function() {
	require('./tooltip');
};

const requireAuthLogin = function() {
	require('./auth/login');
};

const requireAuthRegister = function() {
	require('./auth/register');
};

const requireAuthPasswordReset = function() {
	require('./auth/passwordreset');
};

/**
 * The different page names and their assigned URLs and functions
 * are applied below.
 */
page.setName("search",				["dashboard", "home", "tech/service/search"], requireSearch);
page.setName("workorder",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}", requireTechService);
page.setName("status",				"tech/service/service-call-status");
page.setName("ticket",				"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}", requireTechService);
page.setName("workordersummary",	"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/workordersummary", requireTechService);
page.setName("sow",					"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/sow", requireTechService);
page.setName("signature",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/signature", requireTechService);
page.setName("reqparts",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/reqparts", requireTechService);
page.setName("tasks",				"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/tasks", requireTechServiceTasks);
page.setName('tasks.update',		"tech/service/task");
page.setName("uploadphotos",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/uploadphotos", requireTechServiceUploadPhotos);
page.setName("photo",				"photos/{[A-Z0-9]+}/{[0-9]+}/{(.*)}");
page.setName("uploaddocs",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/uploaddocs", requireTechServiceUploadDocs);
page.setName("parts",				"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts", requireTechServiceParts);
page.setName("parts.install",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/install", requireTechServiceParts);
page.setName("parts.replace",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/replace", requireTechServiceParts);
page.setName("parts.replace.old",	"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/replace/old", requireTechServiceParts);
page.setName("parts.replace.new",	"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/replace/new", requireTechServiceParts);
page.setName("parts.uninstall",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/uninstall", requireTechServiceParts);
page.setName("parts.details.old",	"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/{[0-9]+}/old", requireTechServiceParts);
page.setName("parts.details.new",	"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/{[0-9]+}/new", requireTechServiceParts);
page.setName("parts.details",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/parts/{[0-9]+}", requireTechServiceParts);
page.setName("parts.upload",		"tech/service/parts-upload");
page.setName("forms.api",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/forms/api");
page.setName("forms",				"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/forms", requireTechServiceForms);
page.setName("form",				"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/forms/{[0-9]+}", requireTechServiceForm);
page.setName("form.section",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/forms/{[0-9]+}/{[0-9]+}", requireTechServiceFormSection);
page.setName("form.section.repeat",	"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/forms/{[0-9]+}/{[0-9]+}/{[0-9]+}", requireTechServiceFormSection);
page.setName("photopick",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{[0-9]+}/photopick");
page.setName("kb",					"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/kb", requireTechService);
page.setName("technotes",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/technotes", requireTechServiceTechNotes);
page.setName("workorderdocs",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/workorderdocs", requireTechService);
page.setName("other",				"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/{(.*)}", requireTechService);
page.setName("post_technotes",		"tech/service/tech-notes");
page.setName("subview",				"tech/service/sub-view");
page.setName("upload",				"tech/service/simple-upload");
page.setName("download",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/download/{[0-9]+}");
page.setName("partial",				"tech/service/partial");
page.setName("auth.login",			"login", requireAuthLogin);
page.setName("auth.register",		"register", requireAuthRegister);
page.setName("auth.passwordreset",	["password/reset", "password/reset/{[0-9]+}/{[a-z0-9]+}"], requireAuthPasswordReset);
page.setName("post_checkin",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/checkins/check-in");
page.setName("post_checkout",		"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/checkins/check-out");
page.setName("checkins",			"tech/service/{[A-Za-z0-9]+}/{[0-9]+}/checkins");

page.runCurrentCallback();
