/**
 * @module  fa-file-icon.js
 * @author  Anthony Martin
 *
 * Gets the proper icon class for the designated
 * file extension.
 */

var exports = module.exports = {};

var helpers = require('./helpers');

exports.getIconClassFromExt = function(ext) {
  if (ext instanceof Array) {
    ext = ext[0];
  }
  ext = ext.toLowerCase();
  var extensions = {
    "pdf-o":        ["pdf"],
    "excel-o":      ["xls", "xlsx"],
    "word-o":       ["doc", "docx"],
    "powerpoint-o": ["ppt", "pptx"],
    "code-o":       ["html", "css", "php", "js", "asp", "pl", "py", "rb", "xml"],
    "archive-o":    ["zip", "rar", "bzip", "bzip2", "7z", "tgz"],
    "text-o":       ["txt", "rtf"],
    "image-o":      ["jpg", "jpeg", "tif", "tiff", "gif", "png"],
    "audio-o":      ["wav", "mp3", "mp4a", "ogg"],
    "video-o":      ["avi", "mp4", "mov"]
  };

  var icon = "fa-file-";
  var icon_found = false;

  for (var key in extensions) {
    if (helpers.array_contains(ext, extensions[key])) {
      icon += key;
      icon_found = true;
      break;
    }
  }

  if (!icon_found) icon += "o";

  return icon;
};
