/**
 * @module  thumb.js
 * @author  Anthony Martin
 *
 * This module runs some functions for displaying thumbnails
 * in the TechAppManager.
 *
 * @requires  module:jquery
 * @requires  subview_loader.js
 */

var exports = module.exports = {};

var subview_loader = require('./tech/subview_loader');

exports.thumbnail_width = 150;
exports.size_offset = 30;

$(function() {
	resize_doc_thumbnails();

	$(window).on('resize', function() {
		resize_doc_thumbnails();
	});
});

subview_loader.on({
	enter: function() {
		resize_doc_thumbnails();
	}
});

exports.resize_doc_thumbnail = function($element) {
	resize_doc_thumbnail($element);
};

exports.resize_doc_thumbnails = function() {
	resize_doc_thumbnails();
}

function resize_doc_thumbnails() {
	$(".attachment-thumbnail, .attachment-upload-panel").each(function() {
		resize_doc_thumbnail($(this));
	});
}

function resize_doc_thumbnail(el) {
	if (el instanceof jQuery) {
		el = el[0];
	}

	var width;

	if ($(el).hasClass("attachment-thumbnail")) {
		width = $(".panel", el).width();
	} else {
		width = el.offsetWidth;
	}

	if ($(".doc-thumbnail", el).length) {
		var font_size = Math.min(width, exports.thumbnail_width) - exports.size_offset;
		$(".doc-thumbnail", el).css('font-size', font_size);
	}

	return el;
}
