$(function() {
    $("form.disable-submit").on('submit', function () {
        $("input[type=submit]", this).prop('disabled', true);
        $("button[type=submit]", this).prop('disabled', true);
    });

    $(".logout-btn").on('click', function (event) {
        event.preventDefault();
        $("#logout-form").submit();
    });
});
