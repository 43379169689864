var _console = require('./console'),
	stacktrace = require('stacktrace-js');

var exports = module.exports = {};

_console.logState(true);

var callback = function(stackframes) {
	var stringifiedStack = stackframes.map(function(sf) {
		return sf.toString();
	}).join('\n');
	console.log(stringifiedStack);
};

var errback = function(err) {
	console.log(err.message);
};

window.onerror = function(msg, file, line, col, error) {
	stacktrace.fromError(error).then(callback).catch(errback);
};
