/**
 * @module  transition.js
 * @author  Anthony Martin
 *
 * A UI module that allows for seamless transitions between pages.
 *
 * @requires  transition-loader.scss
 * @requires  console.js
 * @requires  triggers.js
 * @requires  module:jquery
 * @requires	module:bootstrap
 */

var exports = module.exports = {};

var triggers = require('./triggers');

var time = {
	slideInLeft: 400,
	slideInRight: 400,
	slideInDown: 400,
	slideOutRight: 400,
	slideOutUp: 400,
	fadeIn: 400,
	fadeOut: 400
};

var tempEnabled = null;

triggers.set('transition', ['start', 'end']);

exports.on = function(options, callback) {
	triggers.on('transition', options, callback);
}

var transitions = {
	slideInLeft: function(data, callback, popover, extraClasses) {
		runInAnimation("transition-slideInLeft", data, time.slideInLeft, callback, popover, extraClasses);
	},
	slideInRight: function(data, callback, popover, extraClasses) {
		runInAnimation("transition-slideInRight", data, time.slideInRight, callback, popover, extraClasses);
	},
	slideInDown: function(data, callback, popover, extraClasses) {
		runInAnimation("transition-slideInDown", data, time.slideInDown, callback, popover, extraClasses);
	},
	fadeIn: function(data, callback, popover, extraClasses) {
		runInAnimation("transition-fadeIn", data, time.fadeIn, callback, popover, extraClasses);
	},
	fadeOut: function(data, callback, popover, extraClasses) {
		runOutAnimation("transition-fadeOut", data, time.fadeOut, callback, popover, extraClasses);
	},
	slideOutRight: function(data, callback, popover, extraClasses) {
		runOutAnimation("transition-slideOutRight", data, time.slideOutRight, callback, popover, extraClasses);
	},
	slideOutUp: function(data, callback, popover, extraClasses) {
		runOutAnimation("transition-slideOutUp", data, time.slideOutUp, callback, popover, extraClasses);
	},
	close_slideOutUp: function(data, callback, popover, extraClasses) {
		runCloseAnimation("transition-slideOutUp", data, time.slideOutUp, callback, popover, extraClasses);
	},
	close_slideOutRight: function(data, callback, popover, extraClasses) {
		runCloseAnimation("transition-slideOutRight", data, time.slideOutRight, callback, popover, extraClasses);
	}
};

exports.enabled = true;

/**
 * Runs the provided transition with the provided HTML and
 * callbacks.
 * @param  {string}   transition_name The name of the transition
 * @param  {string}   data            The HTML of the page to transition into
 * @param  {Function} callback        The callback function for when the transition
 *                                    animation is completed.
 * @param  {boolean}  popover		  If true, the outer-container is not removed
 * @param  {string}   extraClasses	  Defines any extra classes that will be appended to
 * 								      the container.
 * @return {void}
 */
exports.start = function(transition_name, data, callback, popover = null, extraClasses = '') {
	if (transitions.hasOwnProperty(transition_name)) {
		if (data instanceof jQuery && popover === null) {
			popover = data.hasClass("popover-container");
		}

		triggers.run('transition', 'start', transition_name);
		transitions[transition_name](data, callback, popover, extraClasses);

		exports.restoreEnabled();
	} else {
		console.error("Transition " + transition_name + " does not exist.");
	}
};

/**
 * Runs the `close` variant of the provided transition.
 * @param  {string}   transition_name The name of the transition
 * @param  {jQuery}   $target         A jQuery object representing the
 *                                    container that should be closed.
 * @param  {Function} callback        The callback function for when the transition
 *                                    animation is completed.
 * @return {void}
 */
exports.close = function(transition_name, $target, callback) {
	transition_name = "close_" + transition_name;
	exports.start(transition_name, $target, callback);
};

/**
 * Runs the `close` transition on the current container
 * @param  {string}   transition_name The name of the transition
 * @param  {Function} callback        The callback function for when the transition
 *                                    animation is completed.
 * @return {void}
 */
exports.closeCurrent = function(transition_name, callback) {
	var $container = exports.getCurrentContainer();

	if (!exports.isHomeContainer($container)) {
		exports.close(transition_name, $container, callback);
	} else {
		callback();
	}
};

/**
 * Returns a jQuery object representing the current container
 * @return {jQuery} The current container
 */
exports.getCurrentContainer = function() {
	var overlay_container = $(".outer-container:not(.home-container):not(.hidden)");

	if (overlay_container.length > 0) {
		return overlay_container;
	} else {
		return $(".home-container");
	}
};

/**
 * Makes the home container visible
 * @return {void}
 */
exports.showHomeContainer = function() {
	$(".home-container").removeClass("hidden");
};

/**
 * Hides the home container if it has the .mobile-container class
 * @return {void}
 */
exports.hideHomeContainer = function() {
	if ($(".home-container").hasClass("mobile-container")) {
		if (!exports.isHomeContainer(exports.getCurrentContainer())) {
			$(".home-container").addClass("hidden");
		}
	}
}

/**
 * Returns the home container object
 * @return {jQuery}
 */
exports.getHomeContainer = function() {
	return $(".home-container");
}

/**
 * Determines if the provided container jQuery object
 * is the home container.
 * @param  {jQuery}  $container The container to check
 * @return {Boolean}
 */
exports.isHomeContainer = function($container) {
	return $container.hasClass('home-container');
}

/**
 * Temporarily enables transition animations.
 * 
 * @return {void}
 */
exports.tempEnable = function() {
	tempEnabled = exports.enabled;

	exports.enabled = true;
}

/**
 * Temporarily disables transition animations.
 * 
 * @return {void}
 */
exports.tempDisable = function() {
	tempEnabled = exports.enabled;

	exports.enabled = false;
}

/**
 * Restores transition animation setting to whatever it
 * was before temporarily enabling/disabling.
 * 
 * @return {void}
 */
exports.restoreEnabled = function() {
	if (tempEnabled !== null) {
		exports.enabled = tempEnabled;
	
		tempEnabled = null;
	}
}

/**
 * Runs the "in" animation with the provided class name,
 * HTML, animation length, and callback.
 * @param  {string}   className The name of the transition class
 * @param  {string}   data      The HTML for the new container
 * @param  {int}   	  timeout   The length of the animation
 * @param  {Function} callback  The callback function for when the transition
 *                              animation is complete.
 * @return {void}
 */
function runInAnimation(className, data, timeout, callback = null, popover = false, extraClasses = '') {
	if (!exports.enabled) {
		className = 'transition-none';
		timeout = 0;
	}

	var $container = $("<div/>")
		.addClass("container-fluid")
		.addClass("outer-container")
		.addClass("mobile-container")
		.addClass("transition")
		.addClass(className)
		.addClass(extraClasses)
		.html(data);

	if (popover) {
		$container.addClass("popover-container");
	}

	$("body").append($container);

	setTimeout(function() {
		exports.hideHomeContainer();
		if (popover) {
			$(".outer-container:not(." + className + ", .home-container, .popover-container)").addClass("hidden");
		} else {
			$(".outer-container:not(." + className + ", .home-container)").remove();
		}

		$(".outer-container." + className).removeClass("transition " + className);

		if (callback !== null) {
			triggers.run('transition', 'end', className);
			callback();
		}
	}, timeout);
}

/**
 * Runs the "out" animation with the provided class name,
 * HTML, animation length, and callback.
 * @param  {string}   className The name of the transition class
 * @param  {string}   data      The HTML for the new container
 * @param  {int}   	  timeout   The length of the animation
 * @param  {Function} callback  The callback function for when the transition
 *                              animation is complete.
 * @return {void}
 */
function runOutAnimation(className, data, timeout, callback = null, popover = false, extraClasses = '') {
	if (!exports.enabled) {
		className = 'transition-none';
		timeout = 0;
	}

	var $container = $("<div/>")
		.addClass("container-fluid")
		.addClass("outer-container")
		.addClass("mobile-container")
		.addClass("transition-static")
		.addClass(extraClasses)
		.html(data);
	

	$(".outer-container:not(.home-container, .transition-static)").addClass("transition " + className);

	$("body").append($container);

	setTimeout(function() {
		exports.hideHomeContainer();
		$(".outer-container." + className).remove();
		$(".outer-container.transition-static").removeClass("transition-static");

		if (callback !== null) {
			triggers.run('transition', 'end', className);
			callback();
		}
	}, timeout);
}

/**
 * Runs the "close" animation with the provided class name,
 * target jQuery container, animation duration, and callback.
 * @param  {string}   className The name of the transition class
 * @param  {jQuery}   $target   The container to be closed
 * @param  {int}      timeout   The length of the animation
 * @param  {Function} callback  The callback function for when the transition
 *                              animation is complete.
 * @return {void}
 */
function runCloseAnimation(className, $target, timeout, callback = null, popover = false, extraClasses = '') {
	if (!exports.enabled) {
		className = 'transition-none';
		timeout = 0;
	}

	$target.addClass("transition")
		.addClass(className)
		.addClass(extraClasses);

	if (popover && $(".outer-container.hidden").length > 0) {
		$(".outer-container.hidden:not(.home-container):not(.popover-container)").removeClass("hidden");
	} else if ($(".outer-container.hidden").length > 1) {
		$(".outer-container.hidden:not(.home-container)").removeClass("hidden");
	} else {
		$(".home-container").removeClass("hidden");
	}

	setTimeout(function() {
		$target.remove();

		if (callback !== null) {
			triggers.run('transition', 'end', className);
			callback();
		}
	}, timeout);
}
