/**
 * @module  triggers.js
 * @author  Anthony Martin
 *
 * A JavaScript module-oriented approach to events and listeners.
 * Simply set trigger names with a namespace, and call them anywhere in
 * your application.
 */

var exports = module.exports = {};

var triggers = {};

/**
 * Sets a specified trigger name or array of trigger names.
 * This function must be called before binding a callback to it.
 * 
 * @param {string} namespace The namespace the trigger(s) reside in.
 * @param {string|Array} trigger   Contains the trigger name or array
 *                                 of trigger names.
 */
exports.set = function(namespace, trigger) {
	if (!triggers.hasOwnProperty(namespace)) {
		triggers[namespace] = {};
	}

	if (trigger instanceof Array) {
		for (var i = 0; i < trigger.length; i++) {
			triggers[namespace][trigger[i]] = [];
		}
	} else {
		triggers[namespace][trigger] = [];
	}
};

/**
 * Defines the callbacks related to the provided trigger(s)
 * @param  {string}   namespace 			The namespace the trigger(s) reside in
 * @param  {string|object}   trigger 		A string representing the trigger name, or
 *                                      	an encapsulated object defining the callbacks
 *                                      	of different triggers within the namespace.
 * @param  {Function} callback (optional)   The callback that is applied if the 2nd parameter
 *                             				is a string.
 * @return {void}
 */
exports.on = function(namespace, trigger, callback) {
	if (typeof trigger === 'object') {
		for (var option in trigger) {
			if (option in triggers[namespace]) {
				triggers[namespace][option].push(trigger[option]);
			} else {
				// console.warn(option + " is not registered as a trigger value in namespace " + namespace + ".");
			}
		}
	} else {
		callback = (typeof callback !== 'undefined') ? callback : function(){};
		if (trigger in triggers[namespace]) triggers[namespace][trigger].push(callback);
	}
};

/**
 * Runs the callbacks assigned to the provided namespace
 * and trigger name.
 * @param  {string} namespace 				The namespace the trigger resides in.
 * @param  {string} trigger   				The name of the trigger
 * @param  {string|Array} args (optional)	A single argument for the callback as a string,
 *                             				or an array of arguments to be applied to the callback
 * @return {void}
 */
exports.run = function(namespace, trigger, args) {
	var trigger_funcs = triggers[namespace][trigger];

	if (!(args instanceof Array)) {
		args = [args];
	}

	for (var i = 0; i < trigger_funcs.length; i++) {
		trigger_funcs[i].apply(this, args);
	}
};