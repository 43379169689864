var subview_loader = require('./subview_loader'),
	transition = require('../utilities/transition'),
	page = require('../utilities/page'),
	helpers = require('../utilities/helpers'),
	dropzone = require('dropzone'),
	lightgallery = require('lightgallery'),
	// barcode = require('./barcode'),
	spinner = require('../utilities/spinner'),
	ajax = require('../utilities/ajax'),
	history = require('../utilities/history'),
	dialog = require('bootstrap3-dialog');

var photoDropzone,
	partGallery;
var form_names = ["parts.install", "parts.uninstall", "parts.replace.old", "parts.replace.new", "parts.details.old", "parts.details.new"];
var details_names = ["parts.details", "parts.details.old", "parts.details.new"];
var max_files_exceeded = false;
var lightGallery_options = {
	selector: ".lightgallery_item"
};
var last_alert;

subview_loader.on({
	enter: function(response) {
		var $container = transition.getCurrentContainer();

		if ($("#upload_photos", $container).length > 0) {
			// barcode.supported(function(isSupported) {
			// 	if (isSupported) {
			// 		$("#scan_barcode", $container).removeClass("hidden");
			// 	}
			// });

			var sc = helpers.getTechTicketLocation();
			var url = page.url(subview_loader.current, [sc.service_code, sc.primary_ticket_number, sc.ticket_num, sc.details]);

			photoDropzone = new dropzone(document.body, {
				url: url,
				paramName: "photos",
				method: "post",
				previewsContainer: "#part_photos",
				clickable: "#upload_photos",
				maxFileSize: 10,
				autoProcessQueue: false,
				uploadMultiple: true,
				parallelUploads: 4,
				maxFiles: 4,
				addRemoveLinks: false,
				acceptedFiles: "image/*",
				thumbnailWidth: 256,
				thumbnailHeight: 256,
				previewTemplate: $("#preview-template", $container).html(),
				canceled: function(){},
				init: function() {
					this.on("success", onDropzoneSuccess);
					this.on("removedfile", onDropzoneRemovedFile);
					this.on("error", onDropzoneError);
					this.on("sending", onDropzoneSending);
					this.on("thumbnail", onDropzoneThumbnail);
					this.on("totaluploadprogress", onDropzoneTotalUploadProgress);
					this.on("queuecomplete", onDropzoneQueueComplete);
					this.on("maxfilesexceeded", onDropzoneMaxFilesExceeded);
					this.on("reset", onDropzoneReset);

					this.options.dictMaxFilesExceeded = "You may not select more than " + this.options.maxFiles + " photos.";
				}
			});


			$container.on('click', "#upload_photos", function(event) {
				event.preventDefault();
			});

			// $container.on('click', '#scan_barcode', function(event) {
			// 	event.preventDefault();
			// 	openBarCodeScanner();
			// });

			$container.on('click', '#submit', function(event) {
				event.preventDefault();

				if (inputsFilled()) {
					spinner.show($(this), true);

					$(".subview-body", $container).addClass("has-subheader-alert");
					$(".mobile-subheader-alert", $container).removeClass("hidden");
					$("input,button", $container).attr('disabled', true);

					showBackdrop();

					photoDropzone.processQueue();
				}
			});

			$("input", $container).on('input', function(event) {
				setSubmitButton();
			});
		}

		if (subview_loader.current == "parts.details" || subview_loader.current == "parts.details.old" || subview_loader.current == "parts.details.new") {
			console.log("Initializing Gallery");
			partGallery = $("#lightgallery", $container).lightGallery(lightGallery_options);

			partGallery.on('onSlideItemLoad.lg', function(event, index) {
				$(".lg-download").attr('data-link', true);
			});
		}

		$container.on('click', "#deleteRecordModal", function(event) {
			dialog.show({
				title: "Delete Record?",
				message: "Are you sure you want to delete this record?",
				type: dialog.TYPE_DANGER,
				buttons: [
					{
						label: "Cancel",
						action: function(dialogRef) {
							dialogRef.close();
						}
					},
					{
						label: "Delete",
						cssClass: "btn-danger",
						autospin: true,
						spinicon: 'fa fa-spinner',
						action: function(dialogRef) {
							dialogRef.enableButtons(false);
							dialogRef.setClosable(false);

							var sc = helpers.getTechTicketLocation();
							var details = sc.details;

							if (sc.details.indexOf("-") > -1) {
								// An ID pair is being used; we have to choose the current one
								var parts = sc.details.split("-");

								if (sc.parts[7] == "old") {
									details = parts[0];
								} else {
									details = parts[1];
								}
							}

							var url = page.url('parts.details', [sc.service_code, sc.primary_ticket_number, sc.ticket_num, details]);

							ajax.delete(url, {}, function(response) {
								console.log(response);
								dialogRef.close();
								if (response.status == "success") {
									redirectToParts();
								} else {
									dialog.alert({
										title: "Error",
										type: dialog.TYPE_DANGER,
										message: response.data[0]
									});
								}
							}, function(jqXHR) {
								if (jqXHR.status == 403) {
									window.location.href();
								}
							});
						}
					}
				]
			})
		});

		if (subview_loader.current == "parts") {
			$container.on('click', '.disabled', function(event) {
				event.preventDefault();
				dialog.alert({
					title: "Manage Parts Read Only",
					type: dialog.TYPE_DANGER,
					message: "The current work order is marked as complete.  Changes cannot be made."
				});
			});
		}
	},

	exit: function() {
		console.log("Exiting subview: " + subview_loader.current);
		if ($.inArray(subview_loader.current, form_names) > -1) {
			if (helpers.isDefined(photoDropzone)) {
				photoDropzone.destroy();
			}
		}

		if ($.inArray(subview_loader.current, details_names) > -1) {
			$("#deleteRecordModal").remove();
		}

		if (helpers.isDefined(partGallery) && partGallery.data('lightGallery')) {
			console.log("Destroying Gallery");
			partGallery.data('lightGallery').destroy(true);
		}
	}
});

var onDropzoneSuccess = function(file, response) {
	if (response.status == "success") {
		spinner.hideAll();

		console.log("DROPZONE: Success (" + file.name + ")", response);
	} else {
		var errorMessage = response.data[0];

		console.error("DROPZONE: Error (" + file.name + ")", errorMessage, response);

		if (errorMessage != last_alert) {
			dialog.alert({
				title: "Error",
				type: dialog.TYPE_DANGER,
				message: errorMessage
			});

			last_alert = errorMessage;
		}
	}
};

var onDropzoneRemovedFile = function(file) {
	console.log("DROPZONE: Removed File", file);
	if (photoDropzone.getAcceptedFiles().length < photoDropzone.options.maxFiles) {
		max_files_exceeded = false;
	}
};

var onDropzoneError = function(file, errorMessage, xhr) {
	console.error("DROPZONE: Error(" + errorMessage + ")", xhr)
	photoDropzone.removeFile(file);
	if (errorMessage != last_alert) { // Reduce duplicate error messages
		dialog.alert({
			title: "Error",
			type: dialog.TYPE_DANGER,
			message: errorMessage,
			callback: function() {
				last_alert = "";
			}
		});
		last_alert = errorMessage;
	}
	return false;
};

var onDropzoneThumbnail = function(file, dataUrl) {
	console.log("DROPZONE: Thumbnail Created", file);
	var $container = transition.getCurrentContainer();
	setSubmitButton();
	$("#part_photos_splash").hide();
};

var onDropzoneSending = function(file, xhr, formData) {
	console.log("DROPZONE: Sending File", file);
	var $container = transition.getCurrentContainer();

	var ticket_num = page.splitPath(window.location.href)[4];
	var service_code = page.splitPath(window.location.href)[2];
	var primary_ticket_num = page.splitPath(window.location.href)[3];
	var location = $("#location", $container).val();
	var part_num = $("#part_num", $container).val();
	var serial_num = $("#serial_num", $container).val();

	formData.append('ticket_num', ticket_num);
	formData.append('service_code', service_code);
	formData.append('primary_ticket_num', primary_ticket_num);
	formData.append('location', location);
	formData.append('part_num', part_num);
	formData.append('serial_num', serial_num);

	var asset_types = {
		"parts.install": "new",
		"parts.uninstall": "old",
		"parts.replace.old": "replaced",
		"parts.replace.new": "replacement",
		"parts.details.old": "replaced",
		"parts.details.new": "replacement"
	};

	if (helpers.isDefined(asset_types[subview_loader.current])) {
		formData.append('asset_type', asset_types[subview_loader.current]);
		console.log('Asset Type: ' + asset_types[subview_loader.current]);
	}
};

var onDropzoneReset = function() {
	console.log("DROPZONE: Reset");
	var $container = transition.getCurrentContainer();
	$("#part_photos_splash", $container).show();
	setSubmitButton();
};

var onDropzoneQueueComplete = function() {
	if (photoDropzone.files[0].status != "success") {
		console.warn("queuecomplete fired prematurely");
        // Solve for dropzone.js BUG: https://github.com/enyo/dropzone/issues/578
        // if the first file is invalid then do nothing
        // this event has been fired prematurely
    } else {
		console.log("DROPZONE: Queue Completed");
		var $container = transition.getCurrentContainer();

		$(".subview-body", $container).removeClass("has-subheader-alert");
		$(".mobile-subheader-alert", $container).addClass("hidden");
		$("input,button", $container).attr('disabled', false);
		hideBackdrop();
		redirectToParts();
	}
};

var onDropzoneTotalUploadProgress = function(uploadProgress, totalBytes, totalBytesSent) {
	console.log("DROPZONE: Upload Progress (" + uploadProgress + "%)");
	$("#upload_progress").css('width', uploadProgress + "%");
};

var onDropzoneMaxFilesExceeded = function(file) {
	console.log("DROPZONE: Max Files Exceeded");
};

function setSubmitButton() {
	var $container = transition.getCurrentContainer();
	if (inputsFilled()) {
		$("#submit", $container).attr('disabled', false);
	} else {
		$("#submit", $container).attr('disabled', true);
	}
}

function inputsFilled() {
	var $container = transition.getCurrentContainer();
	var location = $("#location", $container).val();
	var part_num = $("#part_num", $container).val();
	var serial_num = $("#serial_num", $container).val();
	var num_photos = photoDropzone.getQueuedFiles().length;

	return (location != "" && part_num != "" && serial_num != "" && num_photos > 0);
}

function redirectToParts() {
	ajax.clearCache();
	var sc = helpers.getTechTicketLocation();
	var url = '/' + page.removeBaseFromUrl(page.url("parts", [sc.service_code, sc.primary_ticket_number, sc.ticket_num]));

	$(".modal").modal('hide');

	history.pushState(null, document.title, url);
}

// function openBarCodeScanner() {
// 	console.log("Opening barcode scanner.");
// 	transition.start('slideInLeft', $("#barcode-scanner").html(), function() {
//
// 		var $container = transition.getCurrentContainer();
// 		barcode.init($("#interactive", $container));
//
// 		$container.on('click', '#close-barcode-scanner', function(event) {
// 			event.preventDefault();
// 			transition.closeCurrent('slideOutRight', function() {
// 				console.log("Closing current view");
// 				closeBarCodeScanner();
// 			});
// 		});
// 	}, true, "has-sidebar");
// }

// function closeBarCodeScanner(callback) {
// 	callback = helpers.setDefault(callback, function(){});
// 	barcode.stop();
// 	transition.closeCurrent('slideOutRight', function() {
// 		callback();
// 	});
// }

function showBackdrop() {
	$('<div class="mobile-alert-modal fade in"></div>').appendTo(document.body);
    $('<div class="desktop-sidebar-modal fade in"></div>').appendTo(document.body);
    $('<div class="navbar-modal fade in"></div>').appendTo(document.body);
}

function hideBackdrop() {
	$(".mobile-alert-modal").remove();
    $(".desktop-sidebar-modal").remove();
    $(".navbar-modal").remove();
}

// barcode.on('detected', function(result, image) {
// 	console.log("Barcode detected!", format);
//
// 	var code = result.codeResult.code;
// 	var format = result.codeResult.format;
//
// 	closeBarCodeScanner(function() {
// 		var $container = transition.getCurrentContainer();
//
// 		$("#serial_num", $container).val(format + ": " + code);
//
// 		setSubmitButton();
// 	});
// });
//
// barcode.on('processed', function(result) {
// 	if (result && result.codeResult) {
// 		console.log("Found " + result.codeResult.format + " barcode.");
// 	}
// });
