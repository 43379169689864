/**
 * @module  breakpoints.js
 * @author  Anthony Martin
 *
 * Provides a dynamic way to trigger window size breakpoints
 *
 * @requires  console.js
 * @requires  helpers.js
 */

var exports = module.exports = {};

var helpers = require('./helpers');

var breakpoints = {},
	after_element,
	current_breakpoint = null;

/**
 * Sets the callback functions related to a specific
 * breakpoint.  Options include `name`, `enter`, and
 * `exit`.
 * @param  {Object} options And encapsulated object
 *                          representing the options.
 * @return {void}
 */
exports.on = function(options) {
	if ('name' in options) {
		var name = options.name;
		
		if (!(name in breakpoints)) {
			createEmptyBreakpointObject(name);
		}

		if ('enter' in options) breakpoints[name].enter = options.enter;
		if ('exit' in options) breakpoints[name].exit = options.exit;
	} else {
		console.error('"name" is not defined.');
	}
};

/**
 * Determines if the provided breakpoint name
 * is active.
 * @param  {string}  name The breakpoint name to match
 * @return {Boolean}
 */
exports.is = function(name) {
	return isBreakpoint(name);
};

$(function() {
	$(window).resize(function(event) {
		triggerBreakpoint(getCurrentBreakpoint());
	});

	triggerBreakpoint(getCurrentBreakpoint());
});

/**
 * Triggers any functions attached to the given
 * breakpoint name
 * @param  {string} name
 * @return {void}
 */
function triggerBreakpoint(name) {
	if (!(name in breakpoints)) {
		createEmptyBreakpointObject(name);
	}

	var breakpoint = breakpoints[name];

	if (name !== current_breakpoint) {
		if (current_breakpoint in breakpoints) {
			breakpoints[current_breakpoint].exit();
		}

		breakpoint.enter();
	}
	
	current_breakpoint = name;
}

/**
 * Returns the current breakpoint name
 * @return {string}
 */
function getCurrentBreakpoint() {
	after_element = window.getComputedStyle ? window.getComputedStyle(document.body, ':after') : false;

	if (!after_element) {
		return undefined;
	}

	return helpers.trim_quotes(after_element.getPropertyValue('content'));
}

function isBreakpoint(name) {
	return (current_breakpoint == name);
}

function createEmptyBreakpointObject(name) {
	breakpoints[name] = {
		enter: function(){},
		exit: function(){}
	};
}