/**
 * @module  subview_loader.js
 * @author  Anthony Martin
 *
 * Interacts with the TechAppManager subview endpoint to load
 * various different subviews into transition containers.
 *
 * @requires  page.js
 * @requires  helpers.js
 * @requires  ajax.js
 * @requires  transition.js
 * @requires  console.js
 * @requires  history.js
 * @requires  breakpoints.js
 */

var exports = module.exports = {};

var page = require('../utilities/page'),
    helpers = require('../utilities/helpers'),
    ajax = require('../utilities/ajax'),
    transition = require('../utilities/transition'),
    history = require('../utilities/history'),
    breakpoints = require('../utilities/breakpoints'),
    triggers = require('../utilities/triggers');

triggers.set('subview_loader', ['loading', 'loaded', 'enter', 'exit', 'closing', 'closed', 'failed', 'error']);

exports.on = function(options, callback) {
    triggers.on('subview_loader', options, callback);
};

/**
 * Export Commands
 */

exports.page_loading = false;
exports.current = null;
exports.interrupt = false;

/**
 * Closes the current subview without loading an additional
 * one. This is often used to show the home container.
 * @param  {string} animation (optional)    The animation to be used.
 * @return {void}
 */
exports.closeCurrentSubView = function(animation = 'slideOutRight') {
    if (exports.page_loading) {
        triggers.run('subview_loader', 'failed', 'One page at a time!');
    } else {
        exports.page_loading = true;

        triggers.run('subview_loader', 'closing');
        transition.closeCurrent(animation, function() {
            exports.page_loading = false;
            triggers.run('subview_loader', 'closed');
        });
    }
};

/**
 * Loads a subview based on the provided data.
 * @param  {Object}   data                      The data to be passed to the subview endpoint.
 * @param  {Function} callback (optional)       This callback runs when the subview is loaded.
 * @param  {string}   animation (optional)      The animation to be used on the transition container.
 * @param  {Boolean}   ignoreTrigger (optional) Set to true if no triggers should be called with
 *                                              this subview.
 * @param  {Boolean}   ignoreOverlap (optional) Set to true to ignore whether there's another
 *                                              subview loading.
 * @return {void}
 */
exports.loadSubView = function(data, callback = null, animation = 'slideInLeft', ignoreTrigger = false, ignoreOverlap = false) {
    var name = data.name;

    if (exports.page_loading && !ignoreOverlap) {
        triggers.run('subview_loader', 'failed', 'One page at a time!');
    } else {
        if (!ignoreTrigger) {
            if (exports.current !== null) triggers.run('subview_loader', 'exit', exports.current);
            
            triggers.run('subview_loader', 'loading', name);
        }

        if (!exports.interrupt) {
            exports.page_loading = true;
    
            var link = page.url('subview');
    
            console.log("Loading Subview: " + name);
    
            ajax.get(link, data, function(response) {
                if (response.status == "success") {
                    if (!ignoreTrigger) triggers.run('subview_loader', 'loaded', response.data[0]);
                    if (callback === null) {
                        transition.start(animation, response.data[0].html, function() {
                            exports.page_loading = false;
                            exports.current = name;
                            if (!ignoreTrigger) triggers.run('subview_loader', 'enter', response.data[0]);
                        }, false, "has-sidebar");
                    } else {
                        exports.page_loading = false;
                        callback(response.data[0]);
                    }
                } else {
                    console.error("Subview Load Error", link, data, response);
                    if (!ignoreTrigger) triggers.run('subview_loader', 'error', response);
                }
            }, function(jqXHR, textStatus, errorThrown) {
                if (jqXHR.status == 401) {
                    // Unauthorized
                    window.location.href = page.url('auth.login');
                } else if (jqXHR.status == 403) {
                    window.location.reload();
                } else if (jqXHR.status == 404) {
                    exports.page_loading = false;

                    exports.loadSubView({
                        name: "404",
                        primary_ticket_number: data.primary_ticket_number,
                        service_code: data.service_code
                    });
                }
            }, true);
        } else {
            console.log("Loading interrupted!");
            exports.interrupt = false;
        }
    }
};
