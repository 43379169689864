const subview_loader = require('../subview_loader'),
      page = require('../../utilities/page'),
      ajax = require('../../utilities/ajax'),
      history = require('../../utilities/history'),
      spinner = require('../../utilities/spinner'),
      dialog = require('bootstrap3-dialog');

subview_loader.on({
    enter: function(response) {
        if (response.name == "form.questions") {
            console.log("Initializing Form Questions");
            init(response);
        }
    },

    exit: function(name) {
        if ((name == "form.section" || name == "form.section.repeat") && dirty) {
            dialog.alert({
                title: "Changes Discarded",
                message: "The changes you made to this section have been discarded. You must select the Save button to submit your changes before navigating away from this section."
            });

            dirty = false;
        }
    }
});

let dirty = false;

function init(response) {
    bindContinueBtn();
    bindInputChange();
}

function bindContinueBtn() {
    $("#continue").on('click', function(event) {
        event.preventDefault();
        console.log("Continue clicked!");
        let valid = true,
            data = [],
            $btn = $(this),
            href = $btn.data('href');
        
        spinner.show($btn, true);

        $(".question").each(function() {
            let val = getQuestionVal(this),
                id = $(this).data('id');

            if (validateQuestion(this)) {
                // Question is valid, add to data
                data.push({
                    id: id,
                    val: val
                });
            } else {
                valid = false;
            }
        });

        if (valid) {
            console.log("Saving Data...", data);
            saveResponses(data, function(ajaxResponse) {
                if (ajaxResponse.success === true) {
                    ajax.clearCache();
                    dirty = false;

                    history.pushState(null, document.title, href);
                    $(document).trigger('render-sidebar');
                } else {
                    let message = (ajaxResponse.success === false) ? ajaxResponse.error : "There was a problem saving your responses. Please try again later.";

                    dialog.alert({
                        title: "Response Save Error",
                        type: dialog.TYPE_DANGER,
                        message: message
                    });

                    spinner.hide($btn);
                }
            });
        } else {
            spinner.hide($btn);
            dialog.alert({
                title: "Validation Error",
                type: dialog.TYPE_DANGER,
                message: "Please fill out all required form entries."
            });
        }
    });
}

function bindInputChange() {
    $("input, select, button").on('change', function() {
        dirty = true;
    });
}

function validateQuestion(selector) {
    let $question = $(selector),
        type = $question.data('type'),
        required = $question.data('required');
    
    switch (type) {
        case 'file':
            return validateFileQuestion($question, required);
    }

    return validateQuestionVal(getQuestionVal(selector), required);
}

function validateQuestionVal(val, required) {
    if (required) {
        return (/\S/.test(val) && val !== null && typeof val !== "undefined");
    }

    return true;
}

function validateFileQuestion($question, required) {
    let val = getFileQuestionVal($question);
    
    if (required) {
        return val.length > 0;
    }

    return true;
}

function saveResponses(data, callback = null) {
    callback = (callback === null) ? function(){} : callback;

    console.log("Saving to:", window.location.href);

    let json = JSON.stringify(data);

    ajax.post(window.location.href, json, function(response) {
        callback(response);
    }, 'application/json; charset=utf-8');
}

function getQuestionVal(selector) {
    let $question = $(selector),
        type = $question.data('type');
    
    switch (type) {
        case 'radio':
            return getRadioQuestionVal($question);
        case 'text':
            return getTextQuestionVal($question);
        case 'textarea':
            return getTextareaQuestionVal($question);
        case 'file':
            return getFileQuestionVal($question);
    }

    return null;
}

function getRadioQuestionVal($question) {
    let boolean = $question.data('boolean'),
        multi = $question.data('multi');

    if (boolean) {
        let val = $("input:checked", $question).val();
        if (typeof val === 'undefined') {
            return '';
        }

        return val;
    } else if(multi) {
        let choices = [];

        $("input:checked", $question).each(function() {
            choices.push($(this).val());
        });

        return choices;
    } else {
        let val = $("select", $question).val();

        if (val === null) {
            return "";
        }

        return $("select", $question).val();
    }
}

function getTextQuestionVal($question) {
    return $("input", $question).val();
}

function getTextareaQuestionVal($question) {
    return $("textarea", $question).val();
}

function getFileQuestionVal($question) {
    return $(".photopick-btn", $question).data('choices');
}