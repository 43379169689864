/**
 * @module  tooltip.js
 * Enables bootstrap tooltips
 *
 * @requires  module:jquery
 * @requires  module:bootstrap
 */

$(function() {
	$('[data-toggle="tooltip"]').tooltip();
});
