/**
 * @module  upload_docs.js
 * @author  Anthony Martin
 *
 * Runs the features required for the "Upload Documents" subview.
 *
 * @requires  module:jquery
 * @requires  module:dropzone
 * @requires  console.js
 * @requires  subview_loader.js
 * @requires  transition.js
 * @requires  page.js
 * @requires  helpers.js
 * @requires  ajax.js
 * @requires  fa-file-icon.js
 */

var subview_loader = require('./subview_loader'),
    transition = require('../utilities/transition'),
    page = require('../utilities/page'),
    helpers = require('../utilities/helpers'),
    dropzone = require('dropzone'),
    ajax = require('../utilities/ajax'),
    fa_icon = require('../utilities/fa-file-icon'),
    thumb = require('../thumb'),
    dialog = require('bootstrap3-dialog');

var docDropzone,
    processing = false;

subview_loader.on({
    enter: function(response) {
        if (subview_loader.current == 'uploaddocs') {
            var $container = transition.getCurrentContainer(),
                maxFiles = 50,
				parallelUploads = 5;

            if ($("#docupload", $container).length > 0) {
                docDropzone = new dropzone(document.body, {
                    url: page.url('upload'),
                    paramName: "file",
                    method: "post",
                    previewsContainer: "#previews",
                    clickable: "#docupload",
                    filesizeBase: 1024,
					maxFilesize: CONFIG.tech_upload_file_max_size_KB / 1000, // 10MB
                    autoProcessQueue: false,
                    uploadMultiple: false,
                    parallelUploads: parallelUploads, // originally 10
                    maxFiles: maxFiles, // originally 10
                    addRemoveLinks: false,
                    accept: dropzoneAccept,
                    previewTemplate: $("#preview-template").html(),
                    dictMaxFilesExceeded: 'To upload more than ' + maxFiles + ' documents, you must pick a maximum of ' + maxFiles + ' at a time. Please try again with fewer than ' + maxFiles + ' documents.',
                    init: function() {
                        this.on("error", onDropzoneError);
                        this.on("reset", onDropzoneReset);
                        this.on("totaluploadprogress", onDropzoneTotalUploadProgress);
                        this.on("success", onDropzoneSuccess);
                        this.on("processing", onDropzoneProcessing);
                        this.on("queuecomplete", onDropzoneQueueComplete);
                        this.on("sending", onDropzoneSending);
                        this.on("addedfile", onDropzoneAddedFile);
                        this.on("maxfilesexceeded", function(file) {
                            docDropzone.removeFile(file);
                        });
                    }
                });
            }

            $container.on('click', '#upload', function(event) {
              event.preventDefault();

              $("input,button,select", $container).prop('disabled', true);

              $(".subview-body", $container).addClass("has-subheader-alert");
              $(".mobile-subheader-alert", $container).removeClass("hidden");
              showBackdrop();
              docDropzone.processQueue();
            });

            $container.on('click', '#cancel', function(event) {
                event.preventDefault();

                docDropzone.removeAllFiles(true);
            });

            $container.on('input', 'input[name=file_title]', function(event) {
                setUploadBtnState();
            });
        }
    },

    exit: function(name) {
        if (subview_loader.current == 'uploaddocs') {
            if (helpers.isDefined(docDropzone)) {
                docDropzone.destroy();
            }
        }
    }
});

/**
 * DROPZONE FUNCTIONS
 */

var onDropzoneAddedFile = function(file) {
    console.log("DROPZONE: Added " + file.name);
    setUploadBtnState();
};

var onDropzoneRemovedFile = function(file) {
    console.log("DROPZONE: Removed " + file.name);
    setUploadBtnState();
};

var dropzoneAccept = function(file, done) {
    try {
        checkFileType(file);

        // Set the file title input to the file name
        var ext = helpers.getFileExtension(file.name);

        console.log("DROPZONE: File Accepted: " + file.name);
        // Set the file title input to the file name
        $("#file_title", file.previewElement).val(helpers.removeFileExtension(file.name));
        // Set the icon based on the file extension
        $(".doc-thumbnail .fa", file.previewElement).addClass(fa_icon.getIconClassFromExt(ext));

        // Hide the already uploaded list, show the mobile footer, and show the category dropdown
        var $container = transition.getCurrentContainer();

        $("#already_uploaded", $container).addClass("hidden");

        $(".mobile-footer", $container).removeClass("hidden");
        $(".subview-body", $container).addClass("has-footer");
        $("#set-categories", $container).removeClass("hidden");
        $("#dropzone-previews", $container).removeClass("hidden");

        thumb.resize_doc_thumbnails();
        setUploadBtnState();
        done();
    } catch (e) {
        done(e);
    }
};

var errorMessageBuffer = "";

var onDropzoneError = function(file, errorMessage) {
    docDropzone.removeFile(file);
    // docDropzone.removeAllFiles(true);

    if (errorMessageBuffer != errorMessage) {
        var num_dialogs = Object.keys(dialog.dialogs).length + 1,
            buttons = [
                {
                    label: 'OK',
                    action: function(dialogRef) {
                        dialogRef.close();
                    }
                }
            ];

        // If there are more than 3 visible dialogs, show box to dismiss all alerts
        if (num_dialogs > 3) {
            buttons.push({
                label: 'Dismiss all alerts',
                action: function() {
                    $.each(dialog.dialogs, function(id, dialogRef) {
                        dialogRef.close();
                    });
                }
            })
        }

        console.error("DROPZONE: Error:", errorMessage);

        dialog.show({
            title: "Error",
            type: dialog.TYPE_DANGER,
            message: errorMessage,
            buttons: buttons,
            onhide: function(dialogRef) {
                errorMessageBuffer = "";
            }
        });

        errorMessageBuffer = errorMessage;
    }
};

var onDropzoneSuccess = function(file, response) {
    if (response.status == "success") {
        console.log("DROPZONE: Success (" + file.name + ")");
        var name = $("#file_title", file.previewElement).val();
        var ext = helpers.getFileExtension(file.name);
        var id = response.data[0].attachment_id;

        var thumb = createUploadedThumb(name + '.' + ext, ext, id);
        $("#already_uploaded .uploaded_list").prepend(thumb);
    } else {
        console.error("Error: " + response.data[0], response.data);
        dialog.alert({
            title: "Upload Error",
            type: dialog.TYPE_DANGER,
            message: response.data[0]
        });
    }
};

var onDropzoneProcessing = function() {
    // set autoProcessQueue to true after upload is pressed, so every file gets uploaded
	this.options.autoProcessQueue = true;
	processing = true;
}

var onDropzoneQueueComplete = function() {
    if (!processing) {
        console.warn("queuecomplete fired prematurely");
        // Solve for dropzone.js BUG: https://github.com/enyo/dropzone/issues/578
        // if the first file is invalid then do nothing
        // this event has been fired prematurely
    } else {
        // set autoProcessQueue to false when completed
        this.options.autoProcessQueue = false;
        processing = false;

        // Reset progress bar
        $("#upload_progress").css('width', 0);

        console.log("DROPZONE: Queue Complete");
        var $container = transition.getCurrentContainer();

        docDropzone.removeAllFiles();
        ajax.clearCache();
        hideBackdrop();
        $(".subview-body", $container).removeClass("has-subheader-alert");
        $(".mobile-subheader-alert", $container).addClass("hidden");
        $("input,button,select", $container).prop('disabled', false);
        $("#no-documents", $container).addClass("hidden");

        thumb.resize_doc_thumbnails();
    }

};

var onDropzoneSending = function(file, xhr, formData) {
    console.log("DROPZONE: Sending");
    var file_title = $("#file_title", file.previewElement).val();
    var category = $("#file_category", file.previewElement).val();
    var ticket_num = page.splitPath(window.location.href)[4];
    var service_code = page.splitPath(window.location.href)[2];
    var primary_ticket_num = page.splitPath(window.location.href)[3];

    formData.append('ticket_num', ticket_num);
    formData.append('service_code', service_code);
    formData.append('primary_ticket_num', primary_ticket_num);
    formData.append('matching_file_title', file_title);
    formData.append('document_category', category);
    formData.append('is_photo', 0);
};

var onDropzoneReset = function() {
    console.log("DROPZONE: Reset");
    var $container = transition.getCurrentContainer();

    $("#already_uploaded", $container).removeClass("hidden");

    $(".mobile-footer", $container).addClass("hidden");
    $(".subview-body", $container).removeClass("has-footer");
    $("#set-categories", $container).addClass("hidden");
    $("#dropzone-previews", $container).addClass("hidden");
};

var onDropzoneTotalUploadProgress = function(uploadProgress, totalBytes, totalBytesSent) {
    console.log("DROPZONE: Upload Progress (" + uploadProgress + "%)");
    $("#upload_progress").css('width', uploadProgress + "%");
};

/**
 * END DROPZONE FUNCTIONS
 */

function createUploadedThumb(name, ext, id) {
    var $container = transition.getCurrentContainer();
    var thumb_template = $("#uploaded_thumb", $container).html();

    var location = helpers.getTechTicketLocation();

    var $thumb = $(thumb_template);
    $(".panel-footer", $thumb).html(name);
    $(".doc-thumbnail .fa", $thumb).removeClass("fa-file-o").addClass(fa_icon.getIconClassFromExt(ext));
    $(".attachment-thumbnail", $thumb).attr('href', page.url('download', [location.service_code, location.primary_ticket_number, id]));

    return $thumb[0].outerHTML;
}

function showBackdrop() {
    $('<div class="mobile-alert-modal fade in"></div>').appendTo(document.body);
    $('<div class="desktop-sidebar-modal fade in"></div>').appendTo(document.body);
    $('<div class="navbar-modal fade in"></div>').appendTo(document.body);
}

function hideBackdrop() {
    $(".mobile-alert-modal").remove();
    $(".desktop-sidebar-modal").remove();
    $(".navbar-modal").remove();
}

function getAllowedExts() {
    var $container = transition.getCurrentContainer();

    var exts = $("#allowed_exts", $container).val();
    var exts_arr = exts.split(",");
    for (var i = 0; i < exts_arr.length; i++) {
        exts_arr[i] = "." + exts_arr[i];
    }
    return exts_arr.join(',');
}

function setUploadBtnState() {
    var $container = transition.getCurrentContainer();
    var disabled = false;

    var $preview = $("#dropzone-previews", $container);

    $("input[name=file_title]", $preview).each(function() {
        var title = $(this).val();

        if (title == "" || title.length > CONFIG.title_max_length) {
            $(this).parent().addClass("has-error");
            disabled = true;
        } else {
            $(this).parent().removeClass("has-error");
        }
    });

    $("#upload", $container).attr('disabled', disabled);
}

function checkFileType(file) {
    var allowed_exts = getAllowedExts().split(",");

    // Set the file title input to the file name
    var ext = helpers.getFileExtension(file.name);

    if ($.inArray("." + ext, allowed_exts) === -1) {
        var invalid_file_message =  "The file " + file.name + " is invalid.  The following file extensions are allowed: <br />";
        for (var i = 0; i < allowed_exts.length; i++) {
            invalid_file_message += '<span class="label label-default">' + allowed_exts[i] + '</span> ';
        }

        throw invalid_file_message;
    }
}
