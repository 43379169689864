/**
 * @module  page.js
 * @author  Anthony Martin
 *
 * This module provides a way to set and retrieve page names
 * related to URLs.  Those URLs can also trigger callback functions.
 *
 * @requires  console.js
 * @requires  helpers.js
 */

var exports = module.exports = {};

var helpers = require('./helpers');

var set_names = [];
var callbacks = [];

/**
 * Sets a page name and callback function to be matched against. The
 * URLs can be an array of multiples.
 * @param {string}   		name     The name of the page
 * @param {string|array}    urls     The URL(s) to be matched to the page
 * @param {Function|null} 	callback The callback function related to the page name.
 */
exports.setName = function(name, urls, callback = null) {
	if (urls instanceof Array) {
		for (var i = 0; i < urls.length; i++) {
			set_names.push({
				name: name,
				url: helpers.clean_split(urls[i], '/'),
				callback: callback
			});
		}
	} else {
		set_names.push({
			name: name,
			url: helpers.clean_split(urls, '/'),
			callback: callback
		});
	}
}

/**
 * Returns the name corresponding with the current path
 * @return {string} The page name
 */
exports.getCurrentName = function() {
	var pathname = window.location.pathname;
	return exports.getName(pathname);
};

/**
 * Runs the callback related to the current page
 * @return {void}
 */
exports.runCurrentCallback = function() {
	var pathname = window.location.pathname;

	var callback = exports.getCallback(pathname);

	if (callback !== undefined) {
		callback();
	}
}

/**
 * Returns the name corresponding with the `path` parameter
 * @param  {string} path The path to match against
 * @return {string}      The page name
 */
exports.getName = function(path) {
	var setname = getSetName(path);

	if (setname) {
		return setname.name;
	}
	return 'undefined';
};

/**
 * Gets the callback related to the provided `path` parameter
 * @param  {string} path The path to match against
 * @return {Function|undefined}
 */
exports.getCallback = function(path) {
	var setname = getSetName(path);

	if (setname) {
		if (setname.callback !== null) {
			return setname.callback;
		}
	}

	return undefined;
}

/**
 * Returns the url from defined name.
 * @param  {string} name     		The name defined in getName()
 * @param  {array}  [params=[]]   	URL parameters to replace regex values in getName()
 * @param  {int} 	[urlIndex=0]    The index of the URL if there are more than one for this name
 * @return {string}          		The URL
 */
exports.url = function(name, params = [], urlIndex = 0) {
	var paramIndex = 0,
		regex = new RegExp('\{(.*)\}+');

	var url = [],
		final_url = '',
		arr_regex;

	for (var i = 0; i < set_names.length; i++) {
		if (set_names[i].name == name) {
			url.push(set_names[i].url);
		}
	}

	if (url.length > 0) {
		url = url[urlIndex];

		for (var i = 0; i < url.length; i++) {
			if (regex.test(url[i])) {
				arr_regex = new RegExp(url[i].substring(1, url[i].length-1));
				if (arr_regex.test(params[paramIndex])) {
					final_url += params[paramIndex] + '/';

					paramIndex += 1;
				} else {
					return undefined;
				}
			} else {
				final_url += url[i] + '/';
			}
		}

		var retval = exports.addBaseToUrl(final_url);

		return retval.slice(0, -1);
	} else {
		console.warn('URL ' + name + ' not found. Split value returned instead.');
		final_url = name.split('.').join('/');
	}

	var retval = exports.addBaseToUrl(final_url);

	return retval.slice(0, -1);
};

exports.addBaseToUrl = function(url) {
	var getUrl = window.location;
	return getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0] + url;
};

exports.removeBaseFromUrl = function(url) {
	var parts = exports.splitPath(url);
	return parts.join('/');
};

/**
 * Splits a path into usable parts, and removes the protocal
 * and hostname from those parts
 * @param  {string} url The URL to be split
 * @return {Array}     An array of the URL parts
 */
exports.splitPath = function(url) {
	var relative = helpers.getRelativePath(url);
	var parts = relative.split("/");

	parts = parts.filter(Boolean);

	return parts;
};

/**
 * Returns the name applied to the provided path
 * @param  {string} path The path to match
 * @return {string|Boolean}      The name of the page name, or `false`
 */
function getSetName(path) {
	var pathname = helpers.clean_split(path, '/');

	for (var i = 0; i < set_names.length; i++) {
		if (helpers.arrays_match(pathname, set_names[i].url)) {
			return set_names[i];
		}
	}

	return false;
}

/**
 * This function compares two callback functions to determine
 * if they are equal.
 * @param  {Function} callback1
 * @param  {Function} callback2
 * @return {Boolean}
 */
function callbacksAreEqual(callback1, callback2) {
	if (callback1.toString() == callback2.toString()) {
		return true;
	}

	return false;
}

/**
 * This returns the provided callback if it hasn't been called
 * yet.  If it has been called, an empty function is returned
 * @param  {Function} callback
 * @return {Function}
 */
function onlyRunOnce(callback) {
	for (var i = 0; i < callbacks.length; i++) {
		if (callbacksAreEqual(callback, callbacks[i])) {
			return function() {};
		}
	}

	callbacks.push(callback);

	return callback;
}
