/**
 * @module  bootstrap-alerts.js
 * @author  Anthony Martin
 *
 * An extension that allows the easy creation of
 * Bootstrap alerts.
 *
 * @requires  module:jquery
 * @requires  module:bootstrap
 */

var exports = module.exports = {};

var last_alert_content = null;

/**
 * Shows a Bootstrap alert in the given container
 * @param  {string} selector    The selector for the container
 * @param  {string} content     The content of the alert
 * @param  {string} type        The alert type.  Valid entries are:
 *                              'success', 'info', 'warning', 'danger'
 * @param  {Boolean} dismissable Determines whether the alert is dismissable
 * @return {void}
 */
exports.show = function(selector, content, type = 'info', dismissable = false, autodismiss = false, autodismiss_delay = 2000) {
	if (last_alert_content != content) {
		last_alert_content = content;
		var $alert = $("<div/>").addClass("alert alert-" + type).attr("role", "alert").html(content);

		if (dismissable) {
			$alert.addClass('alert-dismissable');

			var $dismiss = $("<button/>").attr({
				"type": "button",
				"aria-label": "Close",
				"data-dismiss": "alert"
			}).addClass("close").html('<span aria-hidden="true">&times;</span>');

			$alert.prepend($dismiss);
		}

		if (autodismiss) {
			setTimeout(function() {
				$alert.fadeOut(function() {
					$(this).remove();
				});
			}, autodismiss_delay);
		}

		$(selector).append($alert);
	}
};
