var subview_loader = require('./subview_loader'),
	transition = require('../utilities/transition'),
	ajax = require('../utilities/ajax'),
	page = require('../utilities/page'),
	helpers = require('../utilities/helpers'),
	spinner = require('../utilities/spinner'),
	dialog = require('bootstrap3-dialog');

subview_loader.on('enter', function(response) {
	if (subview_loader.current == 'technotes') {
		var $container = transition.getCurrentContainer();
		var min_chars = parseInt($("#notes_min_chars", $container).val());
		var max_chars = parseInt($("#notes_max_chars", $container).val());

		$container.on('click', '#savenote', function(event) {
			event.preventDefault();

			var parts = page.splitPath(window.location.href);

			var service_code = parts[2];
			var primary_ticket_number = parts[3];

			$(this).attr('disabled', true);
			$("#newnote", $container).attr('disabled', true);
			spinner.show($(this));

			var current_chars = $("#newnote", $container).val().length;

			if (current_chars > max_chars) {
				dialog.alert({
					title: "Too Long",
					type: dialog.TYPE_DANGER,
					message: "Your note must be less than " + max_chars + " characters."
				});

				$("#newnote", $container).removeAttr('disabled');
				spinner.hideAll();
				set_button_state();
			} else if (current_chars < min_chars) {
				dialog.alert({
					title: "Too Short",
					type: dialog.TYPE_DANGER,
					message: "Your note must be more than " + min_chars + " characters."
				});

				$("#newnote", $container).removeAttr('disabled');
				spinner.hideAll();
				set_button_state();
			} else {
				ajax.post(page.url('post_technotes'), {
					service_code: service_code,
					primary_ticket_number: primary_ticket_number,
					note: $("#newnote", $container).val()
				}, function(response) {
					console.log(response);
					if (response.status == "success") {
						var note_view = response.data[1];

						$("#notes", $container).prepend(note_view);
						$("#no-notes").remove();

						ajax.clearCache();
					} else {
						dialog.alert({
							title: "Error",
							type: dialog.TYPE_DANGER,
							message: response.data[0]
						});
					}
					$("#newnote", $container).val('').removeAttr('disabled');
					spinner.hideAll();
				}, function(jqXHR) {
					if (jqXHR.status == 403) {
						window.location.href();
					}
				});
			}

		});

		$container.on('input', '#newnote', function(event) {
			set_button_state();
		});
	}
});

function set_button_state() {
	var $container = transition.getCurrentContainer();
	var min_chars = parseInt($("#notes_min_chars", $container).val());
	var max_chars = parseInt($("#notes_max_chars", $container).val());

	var current_chars = $("#newnote", $container).val().length;

	$("#notes_num_chars", $container).html(current_chars);

	if (current_chars > max_chars || current_chars < min_chars) {
		$("#num_chars", $container).addClass("text-danger");
	} else {
		$("#num_chars", $container).removeClass("text-danger");
	}

	if ($("#newnote", $container).val() == "") {
		$("#savenote", $container).attr('disabled', true);
	} else {
		$("#savenote", $container).removeAttr('disabled');
	}
}
