const subview_loader = require('./subview_loader'),
      helpers = require('../utilities/helpers');

subview_loader.on({
    enter: function(response) {
        if (subview_loader.current == 'form') {
            if (helpers.isTouchDevice()) {
                console.log("Using touch device! Tooltips disabled.");
            } else {
                $('.section-card').tooltip();
            }
        }
    }
});