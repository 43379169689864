require('bootstrap-select');

$(function() {
    $("#register-btn").on('click', function (event) {
        $("#processing").removeClass("hide");
        $(this).prop("disabled", true);
        $("#registerForm").submit();
    });

    $("#registerForm").on('submit', function () {
        $("#register-btn").prop("disabled", true);
    });

    $("[name=country]").on('change', function(event) {
        var default_country = $(this).data('default'),
            country = $(this).val(),
            $option = $("option[value=" + country + "]", this),
            call_code = $option.data('callcode'),
            call_code_text = (typeof call_code === "undefined") ? "" : "+" + call_code;

        if (country !== default_country) {
            // Is international
            $(".international-form-group").removeClass("hidden");
            $(".domestic-form-group").addClass("hidden");
        } else {
            // Is domestic
            $(".international-form-group").addClass("hidden");
            $(".domestic-form-group").removeClass("hidden");
        }

        $("#call_code_addon").html(call_code_text);

        populateStates(country);
    }).trigger('change');
});

function populateStates(country_code) {
    let $states = $('select[name=state]'),
        country = getCountry(country_code),
        $state;

    $states.empty();

    if (country === null || country.states.length == 0) {
        $states.prop('disabled', true);
    } else {
        for (let state of country.states) {
            $state = $('<option>').val(state.id).html(state.state_name);

            if (state.id == ViewData.old_state) {
                $state.attr('selected', true);
            }

            $states.append($state);
        }

        $states.prop('disabled', false);
    }

    $states.selectpicker('refresh');
}

function getCountry(code) {
    for (let country of ViewData.countries) {
        if (country.country_code == code) {
            return country;
        }
    }

    return null;
}
