/**
 * @module  helpers.js
 * @author  Anthony Martin
 *
 * Some helper functions that can be useful.
 */

var exports = module.exports = {};

var page = require('./page');

/**
 * Returns if the provided value is defined
 * @param  {mixed}  val
 * @return {Boolean}     Returns true if defined,
 *                               false if otherwise
 */
exports.isDefined = function(val) {
	return (typeof val !== 'undefined');
};

/**
 * Removes values from array equal to the provided
 * arguments.
 * @param  {array} arr The array to be modified
 * @param  {...*} args The following arguments
 * @return {array}     The modified array
 */
exports.removeA = function(arr, args) {
	var what, a = arguments, L = a.length, ax;
	while (L > 1 && arr.length) {
		what = a[--L];
		while ((ax = arr.indexOf(what)) !== -1) {
			arr.splice(ax, 1);
		}
	}
	return arr;
};

/**
 * Matches the arrays to see if the values are equal or
 * valid via regex
 * @param  {array} arr1 The first array to be matched
 * @param  {array} arr2 The second array to be matched (can
 *                      contain regex)
 * @return {boolean}    	Returns true if the arrays are matched,
 *                          false if not.
 */
exports.arrays_match = function(arr1, arr2) {
	var is_equal = true,
		regex = new RegExp('\{(.*)\}+'),
		arr_regex;

	if (arr1.length !== arr2.length) {
		return false;
	} else {

		for (var i = 0; i < arr1.length; i++) {
			if (!(arr1[i] == arr2[i])) {
				if (regex.test(arr2[i])) {
					if (arr2[i] !== '{undefined}') {
						arr_regex = new RegExp(arr2[i].substring(1, arr2[i].length-1));

						if (!arr_regex.test(arr1[i])) {
							is_equal = false;
						}
					}
				} else {
					is_equal = false;
				}

			}
		}

		return is_equal;
	}
};

/**
 * Trims the outside quotes away from the provided string
 * @param  {string} str
 * @return {string}
 */
exports.trim_quotes = function(str) {
	return str.replace(/['"]+/g, '');
};

/**
 * Returns whether or not the provided array (haystack)
 * contains a value (needle)
 * @param  {mixed} needle
 * @param  {array} haystack
 * @return {Boolean}
 */
exports.array_contains = function(needle, haystack) {
	for (var i = 0; i < haystack.length; i++) {
		if (haystack[i] == needle) {
			return true;
		}
	}
	return false;
};

/**
 * An helper function extension of the split() function
 * @param  {string} string    The string to be split
 * @param  {string} delimiter The delimiter to be used to
 *                            split the string
 * @return {array}           The split string
 */
exports.clean_split = function(string, delimiter) {
	var parts = string.split(delimiter),
		final_parts = [];

	for (var i = 0; i < parts.length; i++) {
		if (parts[i] !== "") {
			final_parts.push(parts[i]);
		}
	}
	return final_parts;
}

/**
 * Removes the file extension from the provided filename
 * @param  {string} filename
 * @return {string}          The filename without the
 *                           extension
 */
exports.removeFileExtension = function(filename) {
	var parts = filename.split('.');
	parts.splice(-1, 1);

	return parts.join('.');
}

/**
 * Returns the file extension for the provided filename
 * @param  {string} filename
 * @return {string}          The extension
 */
exports.getFileExtension = function(filename) {
	return filename.split('.').pop().toLowerCase();
}

/**
 * Replaces newline \n values with the appropriate line break tag
 *
 * @author  Kevin van Zonneveld (http://kvz.io)
 * @author  Philip Peterson
 * @author  Onno Marsman (https://twitter.com/onnomarsman)
 * @author  Atli Þór
 * @author  Brett Zamir (http://brett-zamir.me)
 * @author  Maximusya
 *
 * @param  {string}  str     The string to parse
 * @param  {Boolean} isXhtml If the HTML is XHTML, then the <br/> tag
 *                           will be used intead of the <br> tag
 * @return {string}          The converted string
 */
exports.nl2br = function(str, isXhtml) {
	var breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>';

	return (str + '')
		.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

/**
 * Extracts the current service code, primary ticket number, secondary
 * ticket number, subview name, and other details from the current page URL
 * @param  {string} url Optional. The URL to extract information from.  If
 *                      		  empty, the current URL will be used.
 * @return {object}     An encapsulated object with the provided data.
 */
exports.getTechTicketLocation = function(url = null) {
	if (url == null) {
		var relative_path = exports.getRelativePath(window.location.href);
	} else {
		var relative_path = exports.getRelativePath(url);
	}

	var parts = relative_path.split("/");

	parts = parts.filter(Boolean);

	var data = {
		service_code: parts[2],
		primary_ticket_number: parts[3],
		parts: parts,
		subview: page.getName(relative_path)
	};

	if (exports.isDefined(parts[4])) {
		if (exports.isNumeric(parts[4])) {
			data.ticket_num = parts[4];
		} else {
			data.subview = parts[4];
		}
	}

	if (exports.isDefined(parts[5])) {
		if (!exports.isNumeric(parts[4])) {
			data.subview_details = parts[5];
		}
	}

	if (exports.isDefined(parts[6])) {
		data.details = parts[6];
	}

	return data;
};

/**
 * Returns true if the current device supports touch.
 * 
 * @return {boolean}
 */
exports.isTouchDevice = function() {
	return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
};

exports.makeID = function(length = 5) {
	var text = "";
	var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	for (var i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}

	return text;
};

exports.createLocationObject = function(url) {
	var a = document.createElement('a');
	a.href = url;
	return a;
};

exports.getRelativePath = function(url) {
	var location = exports.createLocationObject(url);

	return location.pathname + location.search;
};

exports.isNumeric = function(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
};

exports.onlyWhitespace = function(str) {
	if (typeof str === "string") {
		return !str.replace(/\s/g, '').length;
	}

	return true;
};

exports.castBoolean = function(mixed) {
	if (typeof mixed === 'string') {
		return (mixed.toLowerCase() == 'true' || parseInt(mixed) === 1);
	}

	return Boolean(mixed);
};

/**
 * Appends a parameter to the URL and then refreshes the page
 * @param   {string} parameter The name of the parameter to append
 * @param   {string} value The value of the parameter to append
 * @param   {array} except An array of current parameters to ignore
 * @return  {void}
 */
exports.refreshWithParameter = function(parameter, value, except = []) {
	var params = getUrlParameters(except);

	params[parameter] = value;

	window.location.href = generateUrl(params);
}

/**
 * Truncates the given string with the given length and suffix.
 * @param	{string} str
 * @param	{integer} len The maximum string length before truncating.
 * @param	{string} suffix The suffix to append if the maximum length is exceeded.
 * @returns	{string}
 */
exports.truncate = function(str, len = 50, suffix = '...') {
	if (str.length > len) {
		return str.substr(0, len) + suffix;
	}

	return str;
}

/**
 * Gets the parameters from the current URL
 * @param {array} except An array of strings representing what
 *                       parameters should be ignored.
 */
function getUrlParameters(except) {
	var vars = [],
		hash,
		anchor;

	var except = typeof except !== 'undefined' ? except : [];

	var parameters = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

	if (window.location.href.indexOf('?') > -1) {
		for (var i = 0; i < parameters.length; i++) {
			hash = parameters[i].split('=');
			if ($.inArray(hash[0], except) === -1) {
				vars[hash[0]] = hash[1];
			}
		}
	}

	return vars;
}

/**
 * Generates a URL with extra parameters
 * @param {array} parameters An array of strings to be appended
 * @return {string} The URL with the appended parameters
 */
function generateUrl(parameters) {
	var url = location.protocol + '//' + location.host + location.pathname,
		num = 0;

	for (var key in parameters) {
		if (num == 0) {
			url += '?';
		} else {
			url += '&';
		}
		url += key + '=' + parameters[key];
		num++;
	}

	return url;
}
