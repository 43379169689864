/**
 * @module  spinner.js
 * @author  Anthony Martin
 * @version 1.0.1
 *
 * Functions that are used to show and hide the
 * loading spinner on a variety of Bootstrap object types.
 *
 * @requires  module:bootstrap
 * @requires  module:jquery
 * @requires  helpers.js
 */

var exports = module.exports = {};

var helpers = require('./helpers');

/**
 * Shows the spinner on the provided jQuery object
 * @param  {jQuery} $element
 * @return {void}
 */
exports.show = function($element, inverted = false) {
	$element.addClass('mobile-loading');

	var $spinner = exports.get(inverted);

	var showFuncs = {
		"list-group-item": showSpinnerOnListGroupItem,
		"btn": showSpinnerOnBtn,
		"card": showSpinnerOnCard
	};

	runDynFunctionFromElement($element, showFuncs, $spinner);
};

/**
 * Hides the spinner on the provided jQuery object
 * @param  {jQuery} $element
 * @return {void}
 */
exports.hide = function($element) {
	$element.removeClass('mobile-loading');
	var hideFuncs = {
		"list-group-item": hideSpinnerOnListGroupItem,
		"btn": hideSpinnerOnBtn,
		"card": hideSpinnerOnCard
	};

	runDynFunctionFromElement($element, hideFuncs);
};

/**
 * Returns an instance of the spinner
 * @param  {Boolean} [inverted=false] Set to true if the background is dark
 * @return {jQuery}                   The jQuery element of the spinner
 */
exports.get = function(inverted = false) {
	var $spinner = $("<div/>").addClass("loading");
	if (inverted) $spinner.addClass("loading-inverted");
	return $spinner;
}

/**
 * Hides all loaders
 * @return {void}
 */
exports.hideAll = function() {
	$(".mobile-loading").each(function() {
		exports.hide($(this));
	});
}

/**
 * Runs the functions defined in `funcs` related to the
 * $element's class.
 * @param  {jQuery} $element
 * @param  {Object} funcs    The functions related to the
 *                           $element's class
 * @param  {jQuery} $spinner The spinner object
 * @return {void}
 */
function runDynFunctionFromElement($element, funcs, $spinner = null) {
	for (var key in funcs) {
		if (!funcs.hasOwnProperty(key)) continue;

		if ($element.hasClass(key)) {
			funcs[key]($element, $spinner);
			break;
		}
	}
}


/* Spinner Functions */

var showSpinnerOnBtn = function($element, $spinner) {
	$element.data('old-html', $element.html());
	$spinner.css('margin', '0 auto');

	$element.html($spinner);
};

var showSpinnerOnListGroupItem = function($element, $spinner) {
	var $group_icon = $(".list-group-icon.pull-right", $element);

	if ($group_icon.length > 0) {
		$group_icon.data('old-class', $(".fa", $group_icon).attr('class')).html($spinner);
	} else {
		$group_icon = $("<div/>").addClass("list-group-icon pull-right");
		$group_icon.html($spinner);

		$element.append($group_icon);
	}
};

var showSpinnerOnCard = function($element, $spinner) {
	var $card_chevron = $(".card-chevron", $element);

	if ($card_chevron.length > 0) {
		$card_chevron.data('old-class', $(".fa", $card_chevron).attr('class')).html($spinner);
	} else {
		//
	}
};

var hideSpinnerOnBtn = function($element, $spinner) {
	$element.html($element.data('old-html'));
};

var hideSpinnerOnListGroupItem = function($element, $spinner) {
	var $group_icon = $(".list-group-icon.pull-right", $element);

	if ($group_icon.data('old-class') !== '') {
		var $old_icon = $("<i/>").addClass($group_icon.data('old-class'));
		$group_icon.html($old_icon);
	} else {
		$group_icon.remove();
	}
};

var hideSpinnerOnCard = function($element, $spinner) {
	var $card_chevron = $(".card-chevron", $element);

	if ($card_chevron.data('old-class') !== '') {
		var $old_icon = $("<i/>").addClass($card_chevron.data('old-class'));
		$card_chevron.html($old_icon);
	} else {
		$card_chevron.remove();
	}
};
