const subview_loader = require('./subview_loader'),
      spinner = require('../utilities/spinner'),
      ajax = require('../utilities/ajax'),
      transition = require('../utilities/transition'),
      page = require('../utilities/page');

let current_subview = null,
    response,
    max_photos = 4,
    $btn, choices = [];

subview_loader.on({
    enter: function(r) {
        current_subview = r.name;
        response = r;
    }
});

$(function() {
    $("body").on('click', '.photopick-btn', function(event) {
        event.preventDefault();

        if (current_subview !== null) {
            $btn = $(this);

            if ($.isArray($btn.data('choices'))) {
                choices = $btn.data('choices');
            }

            spinner.show($btn, true);
        
            let sc = response.vars.service_code,
                ptn = response.vars.primary_ticket_num,
                tn = response.vars.ticket_num,
                url = page.url('photopick', [sc, ptn, tn]);
                
            ajax.get(page.url('photopick', [sc, ptn, tn]), null, function(response) {
                console.log('Showing Image Picker');
                
                transition.tempEnable();

                transition.start('slideInDown', response, function() {
                    spinner.hide($btn);
                    selectItems();
                }, true);
            }, function (jqXHR) {
                if (jqXHR.status == 403) {
                    window.location.href();
                }
            });
        }
    }).on('click', '.photopick-item', function(event) {
        event.preventDefault();

        if ($(this).hasClass("active")) {
            deactivateItem($(this));
        } else if (getNumSelected() < max_photos) {
            activateItem($(this));
        }

        refreshChoices();
    }).on('click', '[name=photopick-choose]', function(event) {
        setThumbs();
    });
});

function selectItems() {
    let $item;
        
    for (let choice of choices) {
        $item = $(".photopick-item[data-id='" + choice.id + "']");
        
        if ($item.length === 0) {
            console.warn("ID " + choice.id + " not found!");
        }

        activateItem($item);
    }
}

function refreshChoices() {
    choices = [];
    $(".photopick-item.active").each(function() {
        let $item = $(this);
        choices.push({
            id: $item.data('id'),
            t_path: $item.data('thumbnail-path'),
            t_file: $item.data('thumbnail-filename')
        });
    });
}

function activateItem($item) {
    $item.addClass("active panel-primary").removeClass("panel-default");
    updateNumSelected();
}

function deactivateItem($item) {
    $item.removeClass("active panel-primary").addClass("panel-default");
    updateNumSelected();
}

function setThumbs() {
    if ($btn instanceof jQuery) {
        resetThumbs();

        $btn.data('choices', choices);
        let $parent = $btn.parent(),
            $thumb, thumb_index, src;
        
        for (let i = 0; i < 4; i++) {
            thumb_index = i+1;
            $thumb = $(".photopick-thumb[name=photopick-thumb-" + thumb_index + "]", $parent);

            if ($thumb.length === 0) {
                console.warn("Cannot find thumb with index " + thumb_index);
            }

            if (typeof choices[i] === 'undefined') {
                src = $thumb.data('nothing');
            } else {
                src = page.addBaseToUrl(choices[i].t_path + '/' + choices[i].t_file);
            }

            $thumb.attr('src', src);
        }

        $btn.trigger('change');
    } else {
        console.warn("$btn is not an instance of jQuery!");
    }
}

function resetThumbs() {
    if ($btn instanceof jQuery) {
        let $parent = $btn.parent();
        
        $(".photopick-thumb", $parent).each(function() {
            $(this).attr('src', $(this).data('nothing'));
        });
    } else {
        console.warn("$btn is not an instance of jQuery!");
    }
}

function getNumSelected() {
    return $(".photopick-item.active").length;
}

function updateNumSelected() {
    let num_selected = getNumSelected(),
        $badge = $("#photopick-numselected-badge"),
        $choose = $("[name='photopick-choose']");

    $("#photopick-numselected").html(num_selected);

    if (num_selected > 0) {
        $choose.html($choose.data('choose'));
    } else {
        $choose.html($choose.data('empty'));
    }

    if (num_selected >= max_photos) {
        $badge.addClass("badge-danger");
    } else {
        $badge.removeClass("badge-danger");
    }
}
