var subview_loader = require('./subview_loader'),
	helpers = require('../utilities/helpers'),
	page = require('../utilities/page'),
	breakpoints = require('../utilities/breakpoints'),
	transition = require('../utilities/transition'),
	spinner = require('../utilities/spinner'),
	history = require('../utilities/history'),
	ajax = require('../utilities/ajax'),
	dialog = require('bootstrap3-dialog');

transition.enabled = false;

var current_ticket_num = null;

breakpoints.on({
	name: "phone",
	enter: function () {
		transition.getHomeContainer().removeClass("desktop-sidebar").addClass("mobile-container");
		transition.hideHomeContainer();
		transition.enabled = true;
	},
	exit: function () {
		transition.getHomeContainer().addClass("desktop-sidebar").removeClass("mobile-container");
		transition.showHomeContainer();
		transition.enabled = false;
	}
});

subview_loader.on({
	loading: function (name) {
		if (transition.isHomeContainer(transition.getCurrentContainer())) {
			// Loading from home
			$(".container-mobile-splash#loading").show();
		}
		spinner.show($("[data-subview='" + name + "']"));
	},
	loaded: function (response) {
		var $sidebar = $(".desktop-sidebar");

		$(".container-mobile-splash#loading").remove();

		var name = response.name;
		var ticket_num = response.vars.ticket_num;

		spinner.hideAll();
		$(document).trigger("render-sidebar");
		$("[data-subview]").removeClass("active");

		if (ticket_num == null) {
			expandPrimaryMenu();
			$(".ticket-item", $sidebar).removeClass("active");

			$("[data-subview='" + name + "']", $sidebar).addClass("active");
		} else {
			var $ticket_el = $("#ticket_" + ticket_num, $sidebar);

			// Expand sidebar menu if possible
			if ($ticket_el.collapsed()) {
				var data = helpers.getTechTicketLocation();
				data.ticket_num = ticket_num;
				loadSidebarMenu($('.ticket-item[data-ticket-num="' + ticket_num + '"]', $sidebar), data, function () {
					$(".list-group-item", $ticket_el).removeClass("active");
					$("[data-subview='" + name + "']", $ticket_el).addClass("active");
					$(document).trigger("render-sidebar");
				});
			}

			$(".list-group-item", $ticket_el).removeClass("active");
			$("[data-subview='" + name + "']").addClass("active");
		}

	},
	enter: function (response) {
		page.runCurrentCallback();
	},
	failed: function (msg) {
		console.warn(msg);
		spinner.hideAll();
	},
	error: function (response) {
		dialog.alert({
			title: "Error Loading Subview",
			type: dialog.TYPE_DANGER,
			message: "There was an issue loading the page: " + response.data[0]
		});
	}
});

history.on({
	stateChange: function (state) {
		var data = helpers.getTechTicketLocation(state.cleanUrl);
		var path = page.removeBaseFromUrl(state.cleanUrl);

		var fullname = page.getName(path);

		data.name = fullname;

		if (helpers.isDefined(data.subview)) {
			if (data.subview == "workorder") {
				subview_loader.closeCurrentSubView();
			} else {
				var url = state.cleanUrl;
				var animation = getAnimation(url);

				subview_loader.loadSubView(data, null, animation);
			}
		} else {
			console.log("Subview not defined.", data);
		}
	}
});

$(function () {
	/**
	 * Ticket Subview Sidebar listing
	 */
	transition.getHomeContainer().on('click', '.ticket-item', function (event) {
		event.preventDefault();
		event.stopPropagation();

		var data = helpers.getTechTicketLocation();

		data.ticket_num = page.splitPath($(this).attr('href'))[4];
		data.name = 'ticket';

		if (breakpoints.is('phone')) {
			var url = getURL(data.name, data.ticket_num);
			spinner.show($(this));
			history.pushState(null, document.title, url);
		} else {
			loadSidebarMenu($(this), data);
		}
	});

	$("body").on('click', 'a[data-close]', function (event) {
		event.preventDefault();

		var animation = 'slideOutRight';

		if ($(this).data('transition')) {
			animation = $(this).data('transition');
		}

		if ($(this).data('temp')) {
			transition.tempEnable();
		}

		subview_loader.closeCurrentSubView(animation);
	});

	$("body").on('click', 'a:not([data-link]):not([data-close]):not(.mobile-loading).active', function (event) {
		event.preventDefault();
	});

	$("body").on('click', 'a:not([data-link]):not([data-close]):not(.mobile-loading):not(.active):not(.disabled)', function (event) {
		event.preventDefault();

		var data = helpers.getTechTicketLocation();
		var href = $(this).attr('href');

		var page_name = page.getName(href),
			name_parts = page_name.split('.'),
			subview_name = name_parts[name_parts.length - 1],
			url_parts = page.splitPath(href);

		var service_code = url_parts[2],
			primary_ticket_number = url_parts[3],
			ticket_num = url_parts[4];

		spinner.show($(this));

		history.pushState(null, document.title, href);
	});

	$("#markComplete").on('click', '#mark-complete', function (event) {
		event.preventDefault();

		var path = page.url('status');
		var data = helpers.getTechTicketLocation();
		var $this = $(this);
		data.status = 'tech-completed';

		spinner.show($this);

		ajax.put(path, data, function (response) {
			console.log(response);
			spinner.hide($this);
			$("#markComplete").modal('hide');

			if (response.status == "error" && typeof response.data[0] !== 'undefined') {
				dialog.alert({
					type: dialog.TYPE_DANGER,
					title: 'Failed to Mark Work Order as Complete',
					message: response.data[0]
				});
			} else {
				var $btn = $(".btn-mark-completed", transition.getHomeContainer());
				ajax.clearCache();

				$btn.removeClass("list-group-item-info").addClass("list-group-item-success work-order-complete").removeAttr('data-toggle');
				$(".btn-mark-completed-text", $btn).html('Work Order is Complete');
				$(".list-group-icon", $btn).html('<i class="fa fa-fw fa-check-circle"></i>');

				let $checkinBtn = $("#self-check-in", transition.getHomeContainer());
				$checkinBtn.addClass('hidden');
			}
		}, function (jqXHR, textStatus, errorThrown) {
			console.error(jqXHR, textStatus, errorThrown);
			spinner.hide($this);
			$("#markComplete").modal('hide');

			dialog.alert({
				type: dialog.TYPE_DANGER,
				title: 'Failed to Mark Work Order as Complete',
				message: jqXHR.responseText
			});

			if (jqXHR.status == 403) {
				window.location.href();
			}
		});
	});

	transition.getHomeContainer().on('click', '.btn-mark-completed:not([data-toggle])', function (event) {
		event.preventDefault();
	});

	if (transition.isHomeContainer(transition.getCurrentContainer())) {
		// Loading from home
		if (!subview_loader.page_loading) {
			$(".container-mobile-splash#loading").remove();
			expandPrimaryMenu();
		}
	}

	$(document).on('render-sidebar', function () {
		// NOTE: The primary ticket number is used here, as the currently selected ticket number isn't always in the URL
		let service_code = getServiceCode(),
			ticket_num = getPrimaryTicketNumber(),
			href = page.url('forms.api', [service_code, ticket_num, ticket_num]);

		ajax.get(href, {
			status: "complete"
		}, function (response) {
			updateFormsBadge(response);
		}, function(jqXHR) {
			if (jqXHR.status == 403) {
				window.location.href();
			}
		});
	});
});

function updateFormsBadge(data) {
	let total = $("#survey-forms-badge").data('total'),
		completed = 0;

	for (let form of data) {
		if (form.status == 'complete') {
			completed++;
		}
	}

	let $badge = $("<span/>").addClass("badge");

	if (completed < total) {
		$badge.addClass("badge-danger");

		if (total > 1) {
			$badge.html(completed + "/" + total);
		} else {
			$badge.html(total);
		}
	} else {
		$badge.addClass("badge-success");

		if (total > 1) {
			$badge.html(total + "/" + total);
		} else {
			$badge.html(total);
		}
	}

	$("#survey-forms-badge").html($badge);
}

function getTicketNumber() {
	var data = helpers.getTechTicketLocation();
	return data.ticket_num;
}

function getPrimaryTicketNumber() {
	var data = helpers.getTechTicketLocation();
	return data.primary_ticket_number;
}

function getServiceCode() {
	var data = helpers.getTechTicketLocation();
	return data.service_code;
}

function getURL(name, ticket_num) {
	var primary_ticket_number = getPrimaryTicketNumber();
	var service_code = getServiceCode();
	var name_parts = name.split(".");

	var url_parameters = [service_code, primary_ticket_number, ticket_num];

	if (name_parts.length > 1) {
		name = name_parts[0];
		for (var i = 1; i < name_parts.length; i++) {
			url_parameters.push(name_parts[i]);
		}
	}

	var url = page.url(name, url_parameters);

	console.log(url);

	return url;
}

function getAnimation(url) {
	var dir = history.backOrForward(url);

	if (dir == 'back') {
		return 'slideOutRight';
	} else {
		return 'slideInLeft';
	}
}

function loadSidebarMenu($ticket_el, data, callback = function () { }) {
	if (!breakpoints.is('phone')) {
		var $el = $("#ticket_" + data.ticket_num);
		current_ticket_num = data.ticket_num;

		data.subview = 'ticket';

		if ($el.collapsed()) {
			$(".ticket-item").removeClass("active");
			$ticket_el.addClass("active");
			$(".expanded").collapse();

			subview_loader.loadSubView(data, function (response) {
				$el.html(response.html);
				$el.expand();
				callback();
				$(document).trigger("render-sidebar");
			}, 'slideInLeft', true, true);
		} else {
			console.warn("Not collapsed.", $el);
		}
	}
}

function expandPrimaryMenu() {
	var data = helpers.getTechTicketLocation();
	// Expand sidebar menu on primary ticket if possible
	var $sidebar = $(".desktop-sidebar");
	var $ticket_el = $(".ticket-item[data-isprimary='true']", $sidebar);
	var $ticket_menu = $(".list-group-dropdown#ticket_" + data.primary_ticket_number);

	if ($ticket_menu.collapsed()) {
		console.log("Expanding Primary Menu");
		data.ticket_num = $ticket_el.data('ticket-num');
		loadSidebarMenu($('.ticket-item[data-ticket-num="' + data.ticket_num + '"]', $sidebar), data, function () {
			$(".list-group-item", $ticket_el).removeClass("active");
		})
	}
}

$("#self-check-in").on('click', function (event) {
	event.preventDefault();
	handleCheckInOut(false, $(this));
});

$("#self-check-out").on('click', function (event) {
	event.preventDefault();
	handleCheckInOut(true, $(this));
});

$("#mark-complete-after-checkout").on('click', function (event) {
	$("#askIfMarkCompleteAfterCheckout").modal('hide');
	$("#markComplete").modal('show');
});

function handleCheckInOut(isCheckOut, element) {
	let service_code = getServiceCode(),
		ticket_num = getPrimaryTicketNumber();

	let path = isCheckOut ? page.url('post_checkout', [service_code, ticket_num])
		: page.url('post_checkin', [service_code, ticket_num]);

	var $this = element;

	let self_checkout_enabled = $this.data('checkout-enabled');

	spinner.show($this);

	let errMsgTitle = isCheckOut ? 'Failed to check out' : 'Failed to check in';

	ajax.post(path, {}, function (response) {

		spinner.hide($this);

		if (response.status == "error" && typeof response.data[0] !== 'undefined') {
			dialog.alert({
				type: dialog.TYPE_DANGER,
				title: errMsgTitle,
				message: response.data[0]
			});
		} else {
			let $checkinBtn = $("#self-check-in", transition.getHomeContainer()),
				$checkinMsg = $("#check-in-msg", transition.getHomeContainer()),
				$checkoutBtn = $("#self-check-out", transition.getHomeContainer()),
				$markCompleteBtn = $(".btn-mark-completed", transition.getHomeContainer());

			if (isCheckOut) {
				// After check out, we want to hide the checked in message, hide check out button and show Mark Work Order as Complete button.
				// We also want to show check in button if the work order is not complete.
				$checkinMsg.addClass("hidden");
				$checkoutBtn.addClass("hidden");
				$markCompleteBtn.removeClass('hidden');

				if ($markCompleteBtn.hasClass('work-order-complete'))
				{
					$checkinBtn.addClass('hidden');
					dialog.alert({
						type: dialog.TYPE_SUCCESS,
						title: "Success",
						message: "You have been checked out."
					});

				} else {
					$checkinBtn.removeClass("hidden");
					// If work order isn't complete, let's ask the tech if they want to mark it as complete
					$('#askIfMarkCompleteAfterCheckout').modal('show');
				}

			} else {
				// After check in, we want to hide the check in button, show checked in message and show the check out button.
				// We also want to hide Mark Work Order as Complete button if self check-out is enabled until they check out.
				$checkinBtn.addClass("hidden");
				$checkinMsg.removeClass("hidden");
				$checkoutBtn.removeClass("hidden");
				if (self_checkout_enabled == 1) {
					$markCompleteBtn.addClass('hidden');
				}
			}

			ajax.clearCache();
		}
	}, function (jqXHR, textStatus, errorThrown) {
		console.error(jqXHR, textStatus, errorThrown);
		spinner.hide($this);

		dialog.alert({
			type: dialog.TYPE_DANGER,
			title: errMsgTitle,
			message: jqXHR.responseText
		});

		if (jqXHR.status == 403) {
			window.location.href();
		}
	});
}
