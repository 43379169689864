require('bootstrap-select');

$(function() {
    showHideFields();

    $("#region").on('change', function(event) {
        showHideFields();
    });

    $("#loginForm").on('submit', function(event) {
        $("#processing").removeClass("hide");
        $("#login-btn").prop('disabled', true);
    });
});

function showHideFields() {
    let val = $("#region").val(),
        $username = $('[name=username]');

    switch (val) {
        case "US":
            $(".us-only").removeClass("hidden");
            $(".int-only").addClass("hidden");
            $username.attr('type', 'tel');
            break;
        case "non-US":
            $(".us-only").addClass("hidden");
            $(".int-only").removeClass("hidden");
            $username.attr('type', 'text');
            break;
    }
}
