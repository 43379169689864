/**
 * @module  console.js
 * @version 1.1
 * @author  Anthony Martin
 *
 * Provides a dynamic version of window.console that
 * can be turned on and off.
 */

let exports = module.exports = {};

let consoleCache = {
	log: window.console.log,
	error: window.console.error,
	warn: window.console.warn
};

exports.logState = function(visible = false) {
	if (visible) {
		window.console.log = consoleCache.log;
		window.console.error = consoleCache.error;
		window.console.warn = consoleCache.warn;
	} else {
		consoleCache.log = window.console.log;
		consoleCache.error = window.console.error;
		consoleCache.warn = window.console.warn;

		window.console.log = function(){};
		window.console.error = function(){};
		window.console.warn = function(){};
	}
};