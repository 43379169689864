require('bootstrap-select');

$(function () {
    showHideFields();

    $("#reset-link-btn").click(function () {
        $("#processing").removeClass("hide");
        $("#resetLinkForm").submit();
        $(this).prop("disabled", true);
    });

    $("#region").on('change', function (event) {
        showHideFields();
    });
});

function showHideFields() {
    let val = $("#region").val(),
        $username = $('[name=username]');

    switch (val) {
        case "US":
            $(".us-only").removeClass("hidden");
            $(".int-only").addClass("hidden");
            $username.attr('type', 'tel');
            break;
        case "non-US":
            $(".us-only").addClass("hidden");
            $(".int-only").removeClass("hidden");
            $username.attr('type', 'text');
            break;
    }
}
