/**
 * @module  bootstrap-list-group.js
 * @author  Anthony Martin
 *
 * Fixes the some bootstrap list-group bugs.
 *
 * @requires  module:jquery
 * @requires  module:bootstrap
 * @requires  bootstrap-list-group.scss
 */

$(function() {
	$(".list-group").corners();
});

/**
 * Fixes the bug where hidden list items ruin the bumper effect
 */
jQuery.fn.extend({
	corners: function() {
		var $items,
			$first_item,
			$last_item;

		$.each(this, function() {
			$items = $(".list-group-item:visible", $(this));
			$first_item = $items.first();
			$last_item = $items.last();

			$(".list-group-item", $(this)).removeClass("first-item").removeClass("last-item");
			$first_item.addClass("first-item");
			$last_item.addClass("last-item");
		})
	}
});

/**
 * Fixes the list-group-item collapse bug
 */
jQuery.fn.extend({
	collapse: function() {
		$.each(this, function() {
			var $this = $(this);
			$this.removeClass("expanded").addClass("collapsed").removeAttr('style');
		});
	},
	collapsed: function() {
		return ($(this).hasClass("collapsed"));
	},
	expand: function(max_height) {
		max_height = (typeof max_height === 'undefined') ? ($(window).height() - 50 - 45) : max_height;
		$.each(this, function() {
			var $this = $(this);
			$this.removeClass("collapsed").addClass("expanded").css('max-height', max_height);
		});
	}
})
