/**
 * @module  upload_photos.js
 * @author  Anthony Martin
 *
 * Runs the features required for the "Upload Photos" subview.
 *
 * @requires  module:jquery
 * @requires  module:dropzone
 * @requires  console.js
 * @requires  subview_loader.js
 * @requires  transition.js
 * @requires  page.js
 * @requires  helpers.js
 * @requires  ajax.js
 */

var subview_loader = require('./subview_loader'),
	transition = require('../utilities/transition'),
	page = require('../utilities/page'),
	helpers = require('../utilities/helpers'),
	dropzone = require('dropzone'),
	ajax = require('../utilities/ajax'),
	lightgallery = require('lightgallery'),
	dialog = require('bootstrap3-dialog');

var photoDropzone,
	gallery,
	processing = false,
	lightGallery_options = {
		selector: ".attachment-thumbnail"
	};

subview_loader.on({
	enter: function(response) {
		if (subview_loader.current == 'uploadphotos') {
			var $container = transition.getCurrentContainer(),
				maxFiles = 50,
				parallelUploads = 5;

			if ($("#photoupload", $container).length > 0) {
				photoDropzone = new dropzone(document.body, {
					url: page.url('upload'),
					paramName: "file",
					method: "post",
					previewsContainer: "#previews",
					clickable: "#photoupload",
					filesizeBase: 1024,
					maxFilesize: CONFIG.tech_upload_file_max_size_KB / 1000, // 10MB
					autoProcessQueue: false,
					uploadMultiple: false,
					parallelUploads: parallelUploads, // originally 10
					maxFiles: maxFiles, // originally 10
					addRemoveLinks: false,
					accept: dropzoneAccept,
					thumbnailWidth: 150,
					thumbnailHeight: 150,
					// canceled: function() {},
					previewTemplate: $("#preview-template").html(),
					dictMaxFilesExceeded: 'To upload more than ' + maxFiles + ' photos, you must pick a maximum of ' + maxFiles + ' at a time. Please try again with fewer than ' + maxFiles + ' photos.',
					init: function() {
						this.on("error", onDropzoneError);
						// this.on("addedfile", onDropzoneAddedFile);
						this.on("reset", onDropzoneReset);
						this.on("totaluploadprogress", onDropzoneTotalUploadProgress);
						this.on("success", onDropzoneSuccess);
						this.on("processing", onDropzoneProcessing);
						this.on("queuecomplete", onDropzoneQueueComplete);
						this.on("sending", onDropzoneSending);
						this.on("addedfile", onDropzoneAddedFile);
						this.on("removedfile", onDropzoneRemovedFile);
						this.on("maxfilesexceeded", function(file) {
							photoDropzone.removeFile(file);
						});
					}
				});

				$container.on('click', '#upload', function(event) {
					event.preventDefault();
					var $btn = $(this);

					if ($btn.hasClass('disabled')) {
						if (categoryErrorExists()) {
							showCategoryAlert();
						} else if (fileTitleErrorExists()) {
							showFileTitleAlert();
						} else {
							console.warn("Button Disabled!");
						}
					} else {
						$("input,button,select", $container).prop('disabled', true);

						$(".subview-body", $container).addClass("has-subheader-alert");
						$(".mobile-subheader-alert", $container).removeClass("hidden");
						showBackdrop();
						photoDropzone.processQueue();
					}

				});

				$container.on('click', '#cancel', function(event) {
					event.preventDefault();

					photoDropzone.removeAllFiles(true);
				});

				$container.on('click', '#apply-category', function(event) {
					var category = $("#category-list").val();
					$("select[name=file_category]").val(category);
					setUploadBtnState();
				});

				$container.on('change', 'select[name=file_category]', function(event) {
					setUploadBtnState();
				});

				$container.on('input', 'input[name=file_title]', function(event) {
					setUploadBtnState();
				});
			}
			console.log("Initializing Gallery");
			gallery = $("#lightgallery", $container).lightGallery(lightGallery_options);

			// Make download button a link
			gallery.on('onSlideItemLoad.lg', function(event, index) {
				$(".lg-download").attr('data-link', true);
			});
		}
	},
	exit: function() {
		if (subview_loader.current == 'uploadphotos') {
			if (helpers.isDefined(photoDropzone)) {
				photoDropzone.destroy();
			}

			if (helpers.isDefined(gallery) && gallery.data('lightGallery')) {
				console.log("Destroying Gallery");
				gallery.data('lightGallery').destroy(true);
			}
		}
	}
});

/**
 * DROPZONE FUNCTIONS
 */

var errorMessageBuffer = "";

var onDropzoneError = function(file, errorMessage) {
	photoDropzone.removeFile(file);
	// photoDropzone.removeAllFiles(true);

    if (errorMessageBuffer != errorMessage) {
        var num_dialogs = Object.keys(dialog.dialogs).length + 1,
            buttons = [
                {
                    label: 'OK',
                    action: function(dialogRef) {
                        dialogRef.close();
                    }
                }
            ];

        // If there are more than 3 visible dialogs, show box to dismiss all alerts
        if (num_dialogs > 3) {
            buttons.push({
                label: 'Dismiss all alerts',
                action: function() {
                    $.each(dialog.dialogs, function(id, dialogRef) {
                        dialogRef.close();
                    });
                }
            })
        }

		console.error("DROPZONE: Error:", errorMessage);

        dialog.show({
            title: "Error",
            type: dialog.TYPE_DANGER,
            message: errorMessage,
			buttons: buttons,
			onhide: function(dialogRef) {
				errorMessageBuffer = "";
			}
        });

        errorMessageBuffer = errorMessage;
    }
};

var onDropzoneAddedFile = function(file) {
	console.log("DROPZONE: Added " + file.name);
	setUploadBtnState();
};

var onDropzoneRemovedFile = function(file) {
	console.log("DROPZONE: Removed " + file.name);
	setUploadBtnState();
};

var onDropzoneSuccess = function(file, response) {
	if (response.status == "success") {
		console.log("Dropzone Success: " + file.name, response);
		var current = helpers.getTechTicketLocation();
		var $container = transition.getCurrentContainer();

		var src = $(".img-thumbnail", file.previewElement).attr('src');
		var name = $("#file_title", file.previewElement).val();
		var ext = helpers.getFileExtension(file.name);
		var link = page.url('photo', [current.service_code, current.primary_ticket_number, response.data[0].document.server_filename]);

		var thumb = createUploadedThumb(name + '.' + ext, src, link);
		$("#already_uploaded .uploaded_list", $container).prepend(thumb);

		// Destroy and re-initialize lightGallery
		gallery.data('lightGallery').destroy(true);
		gallery = $("#lightgallery", $container).lightGallery(lightGallery_options);
	} else {
		console.error(response.data[0], response);
		dialog.alert({
			title: "Error",
			type: dialog.TYPE_DANGER,
			message: response.data[0]
		});
	}

};

var onDropzoneProcessing = function() {
	// set autoProcessQueue to true after upload is pressed, so every file gets uploaded
	this.options.autoProcessQueue = true;
	processing = true;
};

var onDropzoneQueueComplete = function() {
	if (!processing) {
		console.warn("queuecomplete fired prematurely");
        // Solve for dropzone.js BUG: https://github.com/enyo/dropzone/issues/578
        // if the first file is invalid then do nothing
        // this event has been fired prematurely
    } else {
		// set autoProcessQueue to false when completed
		this.options.autoProcessQueue = false;
		processing = false;

		// Reset progress bar
		$("#upload_progress").css('width', 0);

		console.log("Dropzone Queue Complete");
		var $container = transition.getCurrentContainer();

		photoDropzone.removeAllFiles();
		ajax.clearCache();
		hideBackdrop();
		$(".subview-body", $container).removeClass("has-subheader-alert");
		$(".mobile-subheader-alert", $container).addClass("hidden");
		$("input,button,select", $container).prop('disabled', false);
		$("#no-photos", $container).addClass("hidden");
	}
};

var onDropzoneSending = function(file, xhr, formData) {
	var file_title = $("#file_title", file.previewElement).val();
	var category = $("#file_category", file.previewElement).val();
	var ticket_num = page.splitPath(window.location.href)[4];
	var service_code = page.splitPath(window.location.href)[2];
	var primary_ticket_num = page.splitPath(window.location.href)[3];

	formData.append('ticket_num', ticket_num);
	formData.append('service_code', service_code);
	formData.append('primary_ticket_num', primary_ticket_num);
	formData.append('matching_file_title', file_title);
	formData.append('document_category', category);
	formData.append('is_photo', true);
};

var dropzoneAccept = function(file, done) {
	try {
		checkFileType(file);

		console.log("DROPZONE: File Accepted: " + file.name);
		// Set the file title input to the file name
		$("#file_title", file.previewElement).val(helpers.removeFileExtension(file.name));

		// Hide the already uploaded list, show the mobile footer, and show the category dropdown
		var $container = transition.getCurrentContainer();

		$("#already_uploaded", $container).addClass("hidden");

		$(".mobile-footer", $container).removeClass("hidden");
		$(".subview-body", $container).addClass("has-footer");
		$("#set-categories", $container).removeClass("hidden");
		$("#dropzone-previews", $container).removeClass("hidden");
		setUploadBtnState();
		done();
	} catch (e) {
		done(e);
	}
};

var onDropzoneReset = function() {
	console.log("DROPZONE: Reset");
	var $container = transition.getCurrentContainer();

	$("#already_uploaded", $container).removeClass("hidden");

	$(".mobile-footer", $container).addClass("hidden");
	$(".subview-body", $container).removeClass("has-footer");
	$("#set-categories", $container).addClass("hidden");
	$("#dropzone-previews", $container).addClass("hidden");
};

var onDropzoneTotalUploadProgress = function(uploadProgress, totalBytes, totalBytesSent) {
	console.log("DROPZONE: Upload Progress (" + uploadProgress + "%)");
	$("#upload_progress").css('width', uploadProgress + "%");
};

/**
 * END DROPZONE FUNCTIONS
 */

function createUploadedThumb(name, src, link) {
	var $col = $("<div/>").addClass("col-xs-6 col-sm-4 col-md-3"),
		$a = $("<a/>").addClass("attachment-thumbnail").attr({
			"href": link,
			"title": name,
			"data-link": true,
			"data-lightbox": "#lightbox"
		}),
		$panel = $("<div/>").addClass("panel panel-default"),
		$panel_body = $("<div/>").addClass("panel-body"),
		$panel_footer = $("<div/>").addClass("panel-footer").html(name),
		$photo_thumbnail = $("<div/>").addClass("photo-thumbnail"),
		$img = $("<img/>").addClass("img-responsive img-thumbnail").attr({
			"src": src,
			"width": 150,
			"height": 150
		}).css("display", "inline-block");

	$photo_thumbnail.append($img);
	$panel_body.append($photo_thumbnail);
	$panel.append($panel_body);
	$panel.append($panel_footer);
	$a.append($panel);
	$col.append($a);

	return $col[0].outerHTML;
}

function showBackdrop() {
	$('<div class="mobile-alert-modal fade in"></div>').appendTo(document.body);
    $('<div class="desktop-sidebar-modal fade in"></div>').appendTo(document.body);
    $('<div class="navbar-modal fade in"></div>').appendTo(document.body);
}

function hideBackdrop() {
	$(".mobile-alert-modal").remove();
    $(".desktop-sidebar-modal").remove();
    $(".navbar-modal").remove();
}

function getAllowedExts() {
    var $container = transition.getCurrentContainer();

    var exts = $("#allowed_exts", $container).val();
    var exts_arr = exts.split(",");
    for (var i = 0; i < exts_arr.length; i++) {
        exts_arr[i] = "." + exts_arr[i];
    }
    return exts_arr.join(',');
}

function setUploadBtnState() {
	var $container = transition.getCurrentContainer();
	var disabled = false;

	var $preview = $("#dropzone-previews", $container);

	$("select[name=file_category]", $preview).each(function() {
		if (parseInt($(this).val()) === 0) {
			$(this).parent().addClass("has-error");
			disabled = true;
		} else {
			$(this).parent().removeClass("has-error");
		}
	});

	$("input[name=file_title]", $preview).each(function() {
		var title = $(this).val();

		if (title == "" || title.length > CONFIG.title_max_length) {
			$(this).parent().addClass("has-error");
			disabled = true;
		} else {
			$(this).parent().removeClass("has-error");
		}
	});

	if (disabled) {
		$("#upload", $container).addClass('disabled');
	} else {
		$("#upload", $container).removeClass('disabled');
	}

	// $("#upload", $container).attr('disabled', disabled);
}

function categoryErrorExists() {
	var $container = transition.getCurrentContainer();
	var errorExists = false;

	var $preview = $("#dropzone-previews", $container);

	$("select[name=file_category]", $preview).each(function () {
		if (parseInt($(this).val()) === 0) {
			errorExists = true;
		}
	});

	return errorExists;
}

function showCategoryAlert() {
	var errorMessage = "You must select a category for all photos before uploading.";

	dialog.alert({
		title: "Cannot Upload Photos",
		type: dialog.TYPE_DANGER,
		message: errorMessage
	});
}

function fileTitleErrorExists() {
	var $container = transition.getCurrentContainer();
	var errorExists = false;

	var $preview = $("#dropzone-previews", $container);

	$("input[name=file_title]", $preview).each(function () {
		if ($(this).val() == "") {
			$(this).parent().addClass("has-error");
			errorExists = true;
		} else {
			$(this).parent().removeClass("has-error");
		}
	});

	return errorExists;
}

function showFileTitleAlert() {
	var errorMessage = "Each photo must have a file title before uploading.";

	dialog.alert({
		title: "Cannot Upload Photos",
		type: dialog.TYPE_DANGER,
		message: errorMessage
	});
}

function checkFileType(file) {
	var allowed_exts = getAllowedExts().split(","),
		ext = helpers.getFileExtension(file.name);

	if ($.inArray("." + ext, allowed_exts) === -1) {
		var invalid_file_message = "The file " + file.name + " is invalid.  The following file extensions are allowed: <br />";

		for (var i = 0; i < allowed_exts.length; i++) {
			invalid_file_message += '<span class="label label-default">' + allowed_exts[i] + '</span> ';
		}

		invalid_file_message += '<br /><br />For uploading documents, go to the "Upload Documents" section.';

		throw invalid_file_message;
	}
}
