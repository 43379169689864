/**
 * @module  ajax.js
 * @author  Anthony Martin
 * @version 1.1
 *
 * Provides easy-to-use functions for AJAX calls,
 * and also includes a page caching system.
 *
 * @requires	module:jquery
 * @requires	console.js
 * @requires	laravel-errors.js
 */

var exports = module.exports = {};

var laravel_errors = require('./laravel-errors');

var cache = {};

/**
 * Calls an AJAX GET request with the provided URL
 * and data values.
 * @param  {string}   url      			The URL for the AJAX
 *                             			request.
 * @param  {Object}   data     			An encapsulated object
 *                             			representing the data
 * @param  {Function} callback 			The callback function that
 *                             			is called upon success
 * @param  {Boolean}  [cached=false]	Set to true for the
 *                                   	results to be cached.
 * @param  {string}   [method=GET]		The method to be used in this
 *                                   	ajax call.
 * @return {void}
 */

exports.get = function(url, data, success = function(){}, error = null, cached = false, content_type = 'application/x-www-form-urlencoded; charset=UTF-8') {
	ajaxCall(url, 'GET', data, success, error, cached, content_type);
};

exports.post = function(url, data, success = function(){}, error = null, content_type = 'application/x-www-form-urlencoded; charset=UTF-8') {
	ajaxCall(url, 'POST', data, success, error, false, content_type);
};

exports.put = function(url, data, success = function(){}, error = null, content_type = 'application/x-www-form-urlencoded; charset=UTF-8') {
	ajaxCall(url, 'PUT', data, success, error, false, content_type);
};

exports.delete = function(url, data, success = function(){}, error = null, content_type = 'application/x-www-form-urlencoded; charset=UTF-8') {
	ajaxCall(url, 'DELETE', data, success, error, false, content_type);
};

exports.isCached = isCached;

/**
 * Clears the page cache
 * @return {void}
 */
exports.clearCache = function() {
	clearCache();
};

/**
 * Calls the AJAX request with the provided URL,
 * method, and data values.
 * @param  {string} url     	The URL for the AJAX
 *                           	request.
 * @param  {string} method  	A string representing the
 *                          	endpoint method.
 * @param  {Object} data    	An encapsulated object
 *                           	representing the data to be
 *
 * @param  {function} success 	The success callback function
 * @param  {function} error   	The error callback function
 * @param  {Boolean} cached  	Determines whether the AJAX callback
 *                            	is cached.
 * @return {void}
 */
function ajaxCall(url, method, data, success, error, cached, content_type) {
	if (cached && isCached(url, data)) {
		var response = loadFromCache(url, data);
		success(response);
	} else {
		$.ajax({
			url: url,
			data: data,
			method: method,
			contentType: content_type
		}).done(function(response, textStatus, jqXHR) {
			if (laravel_errors.is_laravel_error(response)) {
				if (error === null) {
					laravel_errors.log(response);
				} else {
					error(jqXHR, textStatus, response);
				}
			} else {
				if (cached) saveToCache(url, data, response);

				success(response);
			}
		}).fail(function(jqXHR, textStatus, errorThrown) {
			if (error === null) {
				if (laravel_errors.is_laravel_error(jqXHR.responseText)) {
					laravel_errors.log(jqXHR.responseText);
				} else {
					console.error(jqXHR.responseText);
				}
			} else {
				error(jqXHR, textStatus, errorThrown);
			}
		});
	}
}

/**
 * Cache Functions
 */

function loadFromCache(path, data) {
	var name = getCachedPageName(path, data);
	return cache[name];
}

function saveToCache(path, data, html) {
	var name = getCachedPageName(path, data);
	cache[name] = html;
}

function clearCache() {
	cache = {};
}

function isCached(path, data) {
	var name = getCachedPageName(path, data);

	return cache.hasOwnProperty(name);
}

function getCachedPageName(path, data) {
	var name = path;

	for (var key in data) {
		name += '|' + key + '=' + data[key];
	}

	return name;
}
