/**
 * @module  laravel-errors.js
 * @version 1.1.0
 * @author  Anthony Martin
 *
 * This module parses laravel exception errors, and allows them
 * to be viewed within the JavaScript console.
 *
 * @requires  module:jquery
 */

var exports = module.exports = {};

/**
 * Parses the provided Laravel exception HTML,
 * and logs it into the console.
 * @param  {string} responseText The exception HTML
 * @return {void}
 */
exports.log = function(responseText) {
	// console.log(responseText);
	var $errors = $(responseText);

	logException($errors);
	logDump($errors);
}

function logException($errors) {
	$errors.find('.block_exception').each(function() {
		var exception_counter = $(this).find('.exception_counter').text();
		var exception_title = $(this).find('.exception_title').text();
		var exception_message = $(this).find('.exception_message').text();

		console.error('Server Exception ' + exception_counter + ' ' + exception_title + ' ' + exception_message);
	});
}

function logDump($errors) {
	var dump = parseDumpKeys($errors);

	console.warn(dump);
}

function parseDumpKeys($errors, $parent = null) {
	var dump = {},
		keyClasses = [
			'sf-dump-public',
			'sf-dump-protected',
			'sf-dump-private',
			'sf-dump-key',
			'sf-dump-index'
		],
		valueClasses = [
			'sf-dump-num',
			'sf-dump-const',
			'sf-dump-str',
			'sf-dump-cchr'
		],
		$el,
		key;
	
	if ($parent === null) $parent = $("samp", $errors).first();

	$errors.find('span[class^="sf-dump"]').each(function() {
		$el = $(this);

		if (isImmediateChild($el, $parent)) {
			if (isKey($el, keyClasses)) {
				key = getDumpKey($el);

				if (isValue($el.next())) {
					dump[key] = $el.next().html();
				} else {
					if (hasContainer($el)) {
						var $c = getContainer($el);
						dump[ley] = parseDumpKeys($c, $c);
					}
				}
			}
		}
	});

	return dump;
}

function isImmediateChild($el, $parent) {

}

function hasContainer($el) {
	return getContainer().is('samp');
}

function getContainer($el) {
	return $el.next().next();
}

function isKey($el, keyClasses) {
	return (keyClasses.indexOf($el.attr('class')) > -1);
}

function isValue($el, valueClasses) {
	return (valueClasses.indexOf($el.attr('class')) > -1);
}

function isRootKey($el) {
	return $el.parent().parent().hasClass('sf-dump');
}

function getDumpKey($el) {
	switch ($el.attr('class')) {
		case 'sf-dump-public':
			return "+" + $el.html();
		case 'sf-dump-protected':
			return "#" + $el.html();
		case 'sf-dump-private':
			return "-" + $el.html();
	}

	return $el.html();
}

function selectorFromClassArray(arr) {
	return "." + arr.join(",.");
}

/**
 * Returns whether or not the provided string is
 * a parse-able Laravel error
 * @param  {[type]}  responseText [description]
 * @return {Boolean}              [description]
 */
exports.is_laravel_error = function(responseText) {
	var $errors = $(responseText);

	if (isException($errors) || isDump($errors)) {
		return true;
	}

	return false;
};

function isException($errors) {
	if ($errors.find('.block_exception').length) {
		return true;
	}

	return false;
}

function isDump($errors) {
	
	if ($errors.find('span[class^="sf-dump"]').length) {
		return true;
	}

	return false;
}