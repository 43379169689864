$(function() {
	$("#processing").addClass("hide");
	$("#search-btn").attr('disabled', false);

	$( "#search-btn" ).on('click', function() {
		$("#processing").removeClass("hide");
		$("#searchForm").submit();
		$(this).attr('disabled', true);
	})
});
