/**
 * @module  history.js
 *
 * Adds Web History to SPA portion of manager App
 *
 * @requires  page.js
 * @requires  triggers.js
 */

var exports = module.exports = {};

var page = require('./page'),
	triggers = require('./triggers');

var ignoreNextTrigger = false,
	lastURL = window.location.href;

triggers.set('history', 'stateChange');

exports.on = function(options, callback) {
	triggers.on('history', options, callback);
};

$(function() {
	history.pushState(
		{cleanUrl: window.location.href},
		document.title,
		window.location.href
	)
	window.onpopstate = (event) => {
		stateChangeTrigger();
	};

	stateChangeTrigger();
});

/**
 * Sets the current state to the provided URL, title, and
 * data information.
 * @param  {Object}  data          			An encapsulated object
 *                                			defining the data.
 * @param  {string}  title         			The title to be shown in
 *                                			the window header.
 * @param  {string}  url           			The URL to change
 * @param  {Boolean} [ignoreTrigger=false]  If true, this push does not
 *                                          activate the stateChange trigger.
 * @return {void}
 */
exports.pushState = function(data, title, url, ignoreTrigger = false) {
	ignoreNextTrigger = ignoreTrigger;
	if (data === null) {
		data = {}
	}
	data.cleanUrl = url;
	var oldHref = '' + window.location.href;
	history.pushState(data, title, url);
	if (oldHref !== url) {
		stateChangeTrigger();
	}
}

/**
 * Determines whether the nextURL will be going
 * backwards or forwards in the page hierarchy.
 * @param  {string} nextURL The upcoming URL
 * @return {string|null}
 */
exports.backOrForward = function(nextURL) {
	var currParts = page.splitPath(lastURL);
	var nextParts = page.splitPath(nextURL);

	if (currParts.length > nextParts.length) {
		return 'back';
	} else if (currParts.length < nextParts.length) {
		return 'forward';
	} else {
		return null;
	}
}

/** Local Function */

function stateChangeTrigger() {
	var State = history.state;
	if (!ignoreNextTrigger) {
		triggers.run('history', 'stateChange', State);
	}
	ignoreNextTrigger = false;
	lastURL = State.cleanUrl;
}
