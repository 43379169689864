var subview_loader = require('./subview_loader'),
	transition = require('../utilities/transition'),
	ajax = require('../utilities/ajax'),
	page = require('../utilities/page'),
	spinner = require('../utilities/spinner'),
	alerts = require('../utilities/bootstrap-alerts'),
	dialog = require('bootstrap3-dialog');

var ajax_path = page.url('tasks.update');

subview_loader.on({
	loaded: function(response) {
		if (response.name == 'tasks') {
			$("#exceptionModal").remove();
		}
	},
	enter: function(response) {
		if (subview_loader.current == 'tasks') {
			var $container = transition.getCurrentContainer();

			$("#exceptionModal").appendTo("body");

			$(".task .dropdown-menu", $container).on('click', 'a:not([data-toggle])', function(event) {
				event.preventDefault();
				var action = $(this).attr('href').slice(1);
				var id = $(this).data('task-id');

				switch (action) {
					case 'complete': markTaskComplete(id); break;
					case 'exception': markTaskException(id); break;
					case 'clear': clearTaskStatus(id); break;
				}
			});

			$(".task", $container).on('click', 'button.list-group-item', function(event) {
				if ($(this).hasClass("disabled")) {
					dialog.alert({
						title: "Tasks Read Only",
						type: dialog.TYPE_DANGER,
						message: "The current work order is marked as complete.  Changes cannot be made."
					});
				}
			});

			$("#exceptionModal").on('show.bs.modal', function(event) {
				var button = $(event.relatedTarget);
				var task_id = button.data('task-id');
				var modal = $(this);

				$("#save-exception", modal).data('task-id', task_id);
				$("#exception-details", modal).val('');
				$(".alerts", modal).html('');
			});

			$("#exceptionModal").on('click', '#save-exception', function(event) {
				var $modal = $("#exceptionModal");
				var task_id = $(this).data('task-id');
				var task_resolution = $("#exception-details", $modal).val();

				if (task_resolution == '') {
					$(".alert", $(".alerts", $modal)).remove();
					alerts.show($(".alerts", $modal), 'The exception details are empty!', 'danger');
				} else {
					$modal.modal('hide');
					markTaskException(task_id, task_resolution);
				}
			});
		}
	}
});

function markTaskComplete(id) {
	console.log("Marking task " + id + " as complete.");

	var data = baseData();
	data.task_status = "complete";
	data.task_id = id;

	spinner.show(taskElement(id));

	ajax.put(ajax_path, data, function(response) {
		if (response.status == "success") {
			var response_data = response.data[0];
			spinner.hide(taskElement(response_data.id));
			setTaskColor(response_data.id, 'success', 'fa-check-circle');
			ajax.clearCache();
			$(".exception-text", taskElement(response_data.id)).html('').addClass('hidden');
		} else {
			showError(response.data[0]);
			spinner.hide(taskElement(id));
			ajax.clearCache();
		}
	}, function(jqXHR) {
		if (jqXHR.status == 403) {
			window.location.href();
		}
	});
}

function markTaskException(id, resolution) {
	console.log("Marking task " + id + " as exception.");

	var data = baseData();
	data.task_status = "exception";
	data.task_id = id;
	data.task_resolution = resolution;

	spinner.show(taskElement(id));

	ajax.put(ajax_path, data, function(response) {
		if (response.status == "success") {
			var response_data = response.data[0];
			spinner.hide(taskElement(response_data.id));
			setTaskColor(response_data.id, 'danger', 'fa-exclamation-triangle');
			ajax.clearCache();
			$(".exception-text", taskElement(response_data.id)).html(response_data.resolution).removeClass('hidden');
		} else {
			showError(response.data[0]);
			spinner.hide(taskElement(id));
			ajax.clearCache();
		}
	}, function(jqXHR) {
		if (jqXHR.status == 403) {
			window.location.href();
		}
	});
}

function clearTaskStatus(id) {
	console.log("Clearing task " + id + " status.");

	var data = baseData();
	data.task_status = "open";
	data.task_id = id;

	spinner.show(taskElement(id));

	ajax.put(ajax_path, data, function(response) {
		if (response.status == "success") {
			var response_data = response.data[0];
			spinner.hide(taskElement(response_data.id));
			setTaskColor(response_data.id, 'default', 'fa-circle-thin');
			ajax.clearCache();
			$(".exception-text", taskElement(response_data.id)).html('').addClass('hidden');
		} else {
			showError(response.data[0]);
			spinner.hide(taskElement(id));
			ajax.clearCache();
		}
	}, function(jqXHR) {
		if (jqXHR.status == 403) {
			window.location.href();
		}
	});
}

function baseData() {
	var parts = page.splitPath(window.location.href);
	var data = {};

	data.service_code = parts[2];
	data.primary_ticket_number = parseInt(parts[3]);
	data.ticket_num = parseInt(parts[4]);

	return data;
}

function taskElement(id) {
	var $container = transition.getCurrentContainer();
	return $(".list-group-item[data-task-id=" + id + "]", $container);
}

function showError(error) {
	dialog.alert({
		title: "Error",
		type: dialog.TYPE_DANGER,
		message: error
	});
}

function setTaskColor(id, color, icon) {
	var $task = taskElement(id);

	$task.removeClass('list-group-item-success list-group-item-warning list-group-item-danger list-group-item-info');
	$(".dropdown-toggle", $task).removeClass('btn-success btn-warning btn-danger btn-info');

	$task.addClass('list-group-item-' + color);
	$(".list-group-icon", $task).html('<i class="fa ' + icon + ' fa-2x"></i> <i class="fa fa-caret-down"></i>');
}
