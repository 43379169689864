/**
 * @module  app.js
 * @author  Anthony Martin
 *
 * Main App JS
 * Using browserify to compile
 *
 * Loads the different modules into variables, applies universal functions, and dynamically
 * runs the modules using the require() function
 *
 * @requires  module:jquery
 * @requires	module:bootstrap
 * @requires  page.js
 * @requires  console.js
 * @requires  bootstrap-list-group.js
 * @requires  ie10-viewport-bug-workaround.js
 */

// Build Utilities
require("core-js");

// Globals
window.$ = window.jQuery = require('jquery');

////////////////////////////////
// COMMENT OUT FOR PRODUCTION //
////////////////////////////////
require('./utilities/debug');

// Node Modules
require('bootstrap');

// Local Modules
const page = require('./utilities/page');

require('./utilities/bootstrap-list-group');
require('./lib/ie10-viewport-bug-workaround');
require('./all');
require('./routes');
// require('../../../public/vendor/custom_field/custom_field');


console.log("Current Page: " + page.getCurrentName());
